import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route } from 'react-router-dom';

import createStore from 'store';

import ErrorBoundary from './ErrorBoundary';
import SiteContainer from './SiteContainer';

const { store } = createStore();

ReactDOM.render(
	<Provider store={store}>
		<ErrorBoundary>
			<Router>
				<Route path="/" component={SiteContainer} />
			</Router>
		</ErrorBoundary>
	</Provider>, document.getElementById('container')
);