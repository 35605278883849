import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from 'app/config'
import axios from 'axios';
import { isEmpty } from 'lodash-es';

import DeliveryDetailsMap from "./DeliveryDetailsMap";
import DeliveryDetailsThumbs from "./DeliveryDetailsThumbs";
import DeliveryDetailsTimelineItem from './DeliveryDetailsTimelineItem';
import { translate } from '../../../common/translations';
import DeliveryDetailsAddress from './DeliveryDetailsAddress';
import {filterScanLog, groupScanLogByStatus} from 'common/filterScanLog';
class CustomerSearchForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			extraCare: null
		}
	}

	componentDidMount() {
		const props = this.props;
		const delivery = this.props.delivery;
		const deliveryConfig = this.props.deliveryConfig;

		if (delivery && delivery.clientId === 182 && deliveryConfig.extraCareEnabled) {
			const city = delivery.city;
			const postcode = delivery.postcode;
			const date = delivery.deliveryDateDt;
			const formattedDate = date

			axios.get(`https://api.homedelivery.com.au/api/vics-meat?suburb=${city}&postcode=${postcode}&date=${formattedDate}`)
				.then(response => {
					if (response.data.includes('Unknown')) {
						this.state({
							extraCare: ''
						})
					} else {
						this.setState({
							extraCare: response.data
						})
					}
				})
				.catch(error => {
					console.debug(error);
				});
		}
	}

	render() {
		const { extraCare } = this.state;
		const props = this.props;
		const delivery = this.props.delivery;
		const deliveryConfig = this.props.deliveryConfig;
		const { boxes, scanLog } = delivery;
		const styles = deliveryConfig.clientStyles;

		console.debug('Delivery Status: Delivered');
		
		// Filter scanLog to remove duplicate status pick ups eg 2x 'pick up' scans for same box
		const scanLogFiltered = filterScanLog(scanLog, boxes);
		const scanLogGrouped = groupScanLogByStatus(scanLogFiltered);

		if (delivery.deliveryNotes) {
			delivery.deliveryNotes = delivery.deliveryNotes.trim() == '' ? '' : delivery.deliveryNotes;
		} else {
			delivery.deliveryNotes = '';
		}

		delivery.driversNotes = delivery.driversNotes == '' ? translate(props.lang, 'No driver note') : delivery.driversNotes;

		let deliveryImages = [];

		if (delivery.assets?.length > 0) {
			deliveryImages = delivery.assets.map(asset => asset.uri);
		}else{
			deliveryImages = [delivery.photo1, delivery.photo2, delivery.photo3, delivery.photo4, delivery.photo5]
				.filter(image => !isEmpty(image))
				.map(image => config.apiBase + '/api/DeliveryImage/' + image);
		}

		const DeliveryImageContent = deliveryImages.length === 0 ? null : (
			<div key="deliveryImagesTitle" className="row division white-bg rounded-lg">
				<div className="col-12 text-center mb-3">
					<h3 style={styles.textHighlight}>{translate(props.lang, 'Delivery Photos')}</h3>
				</div>

				<div key="deliveryImagesPhotos" className="row col-12 ml-0 mr-0 d-flex justify-content-between justify-content-sm-center">
					{deliveryImages.map((img, idx) => (
						<div key={`photo${idx}`} className="mr-4 mb-4">
							<a href={img} target="_blank">
								<img src={img} className="img-thumbnail" style={{width: 120, height: 120, objectFit: 'cover'}} />
							</a>
						</div>
					))}
				</div>
			</div>
		);

		const DeliveryNotesContent = (deliveryConfig.showDeliveryNotes) ? (
			<div className="row division white-bg rounded-lg">
				<div className="col-md-6">
					<h3 className="subheading" style={styles.textHighlight}>{translate(props.lang, 'Delivery Notes')}</h3>
					<p className="mb-lg-0">
						{isEmpty(delivery.deliveryNotes) ? 'No delivery notes' : delivery.deliveryNotes}
					</p>
				</div>

				{/*<div className="col-md-6">*/}
				{/*	<h3 className="subheading" style={styles.textHighlight}>{translate(props.lang, "Driver's Notes")}</h3>*/}
				{/*	<p className="mb-0">*/}
				{/*		{delivery.driversNotes}*/}
				{/*	</p>*/}
				{/*</div>*/}
			</div>
		) : null;

		const Rating = (deliveryConfig.showSubmitIssue && (delivery.deliveryStatus === 'Completed' || delivery.deliveryStatus === 'Delivered') || delivery.deliveredDt) ? [
			<DeliveryDetailsThumbs lang={props.lang} key="rating-form" delivery={delivery} deliveryConfig={deliveryConfig} />
		] : null;

		const data = {
			displayMessage: delivery.deliveryStatus,
			deliveryDate: delivery.deliveredDt ?? delivery.deliveryDateDt,
			deliveryTime: delivery.deliveryTime,
			status: 'delivered'
		}

		return (
			<div className="deliveryDetails row">
				<div className="col-12">
					<div className="row division white-bg">
						<div className="col-12 text-center">
							<h3>{translate(props.lang, 'Time Delivered')}</h3>
							<p style={styles.textHighlight} className="deliveryTime mb-0">{delivery.deliveryTime}</p>
							<p style={styles.textHighlight} className="deliveryDate">{delivery.deliveryDate}</p>
						</div>
					</div>

					{deliveryConfig.showDeliveryDetails ?
						<>
							<DeliveryDetailsMap lang={props.lang}
								latitude={delivery.latitude}
								longitude={delivery.longitude}
								deliveryConfig={deliveryConfig}
							/>

							{boxes &&
								<DeliveryDetailsAddress lang={props.lang}
									delivery={delivery}
									styles={styles}
									boxes={boxes}
								/>
							}

							{extraCare &&
								<p className="flex-d text-center mt-4 font-weight-bold" style={{ color: 'black' }}>{extraCare}</p>
							}

							{/* Reminder: Remove this when driver app is stood up */}
							<div className="row division white-bg timeline-container px-5 rounded-b-lg pt-0">
								<DeliveryDetailsTimelineItem lang={props.lang}
									key={data.scannedDt}
									styles={styles}
									data={data}
									delivered
								/>

								{
									Object.keys(scanLogGrouped).map((key) => (
										<DeliveryDetailsTimelineItem lang={props.lang}
											key={[...scanLogGrouped[key]].pop().scannedDt}
											styles={styles}
											data={[...scanLogGrouped[key]].pop()}
											deliveryStatus={delivery.deliveryStatus}
											boxScans={scanLogGrouped[key]}
										/>
									))
								}
							</div>
						</>
						: null
					}

					{DeliveryNotesContent}
					{DeliveryImageContent}
					{Rating}

					<p className="flex-d text-center mt-4">&nbsp;</p>
				</div>
			</div>
		);
	}
}

CustomerSearchForm.contextTypes = {

};

const mapStateToProps = (state) => ({
	isEditingContact: state.isEditingContact
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearchForm);
