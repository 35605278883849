import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectAsyncReducer } from 'io-redux/store';
import { isEmpty } from 'lodash-es';
import { reducers as deliveryReducers, actionCreators as deliveryActionCreators } from '../resource/delivery';
import { translate } from '../../../common/translations';
injectAsyncReducer('DELIVERY_NOTE_ITEM', deliveryReducers("DELIVERY_NOTE_ITEM", ["UPDATE"]));

class DeliveryDetailsDeliveryNotesForm extends Component {

	constructor(props) {
		super(props);

		let dns = 'OTHER';

		if (props.delivery.deliveryNotes === '') {
			dns = 'NO_DELIVERY_NOTE';
		} if (props.delivery.deliveryNotes === 'Please leave at front door') {
			dns = 'LEAVE_FRONT_DOOR';
		} else if (props.delivery.deliveryNotes === 'Please attempt to call on Arrival if after 7am') {
			dns = 'CALL_ON_ARRIVAL_AFTER_7AM';
		} else if (props.delivery.deliveryNotes === 'Please ring Doorbell/knock on Door if after 7am') {
			dns = 'KNOCK_DOOR_AFTER_7AM';
		} else if (props.delivery.deliveryNotes === 'Leave Undercover') {
			dns = 'LEAVE_UNDERCOVER';
		} else if (props.delivery.deliveryNotes === 'Please attempt to leave out of sight') {
			dns = 'LEAVE_OUT_OF_SIGHT';
		}

		this.state = {
			originalDeliveryNotesSelection: dns,
			deliveryNoteSelection: dns,
			deliveryNotes: props.delivery.deliveryNotes || '',
			editDeliveryNotes: props.delivery.deliveryNotes || '',
			isEditing: false,
			hasDeliveryNotesChanged: false,
			pendingUpdate: props.delivery.pendingUpdate || []
		}

	}

	componentWillReceiveProps(newProps, newState) {
		if ((newProps.item && newProps.item.error) || newProps.itemAction.error) {

			this.setState({ isSubmitting: false, errorMessage: 'Unable to save delivery details', successMessage: '' });
			return;
		}

		if (newProps.itemAction.hasUpdated === true) {

			this.setState({
				isEditing: false,
				isSubmitting: false,
				errorMessage: '',
				successMessage: 'Details Saved',
				deliveryNotes: newProps.item.data.deliveryNotes,
				editDeliveryNotes: newProps.item.data.deliveryNotes,
				pendingUpdate: newProps.item.data.pendingUpdate || []
			});
			return;
		}
	}

	submitForm(e) {
		e.preventDefault();
		const options = {
			clientId: this.props.deliveryConfig.clientId,
			postcode: this.props.delivery.postcode,
			deliveryNotes: this.state.deliveryNoteSelection === 'OTHER' ? this.state.editDeliveryNotes : this.state.deliveryNoteSelection,
		};
		this.props.crudActions.update(
			encodeURIComponent(this.props.delivery.clientDeliveryIdentifier),
			options,
			null,
		);
		this.setState({ errorMessage: '', successMessage: '', isSubmitting: true, hasDeliveryNotesChanged: false, originalDeliveryNotesSelection: options.deliveryNotes })
	}

	cancelForm() {
		this.setState({ isEditing: false, editDeliveryNotes: this.state.deliveryNotes });
	}

	toggleForm() {
		this.setState({ isEditing: !this.state.isEditing });
	}

	handleDeliveryNoteSelection = (ev) => {
		this.setState({
			deliveryNoteSelection: ev.target.value
		})

		this.handleDeliveryDetailsChanges(ev, false);
	}

	handleCustomDeliveryNoteChange = (ev) => {
		this.setState({
			editDeliveryNotes: ev.target.value
		});

		this.handleDeliveryDetailsChanges(ev, true);
	}

	handleDeliveryDetailsChanges = (ev, isNote) => {
		let newState = false;

		let newValue = ev.target.value.trim();
		let oldValue = '';

		if (isNote) {
			oldValue = this.props.delivery.deliveryNotes.trim();
		} else {
			oldValue = this.state.originalDeliveryNotesSelection;
		}

		this.setState({
			hasDeliveryNotesChanged: newValue !== oldValue
		});
	}

	render() {
		const props = this.props;
		const styles = props.deliveryConfig.clientStyles;
		let pendingNote = "";

		if (this.state.pendingUpdate && this.state.pendingUpdate.patchDocument) {
			var patchDocument = JSON.parse(this.state.pendingUpdate.patchDocument),
				patchDocumentLength = patchDocument.length;

			patchDocument.forEach(item => {
				if (item.path === "/CustomerDeliveryNotes") {
					pendingNote = item.value;
				}
			})

			if (patchDocumentLength > 0) {
				for (var i = 0; i < patchDocumentLength; i++) {

				}
			}

			if (patchDocument.length > 0) {

			}
		}



		return (
			<div style={{ width: "100%" }} className="">
				<div className={(this.state.isEditing === true && props.deliveryConfig.portalCustomerModifyData ? 'hidden' : '') + " col-12 text-center"}>
					<h3 className="subheading" style={styles.textHighlight}>{translate(this.props.lang, 'Delivery Notes')}</h3>
					<p>{isEmpty(this.state.deliveryNotes) ? 'No delivery notes' : this.state.deliveryNotes}</p>

					{pendingNote !== '' && (
						<p><strong>{translate(this.props.lang, 'Delivery Note Pending Approval')}:</strong> {pendingNote}</p>
					)}

					{this.state.successMessage ? <p>{this.state.successMessage}</p> : null}
					{props.deliveryConfig.portalCustomerModifyData ? <div className="form-group mb-0">
						<input style={styles.button} type="button" onClick={this.toggleForm.bind(this)} className="trackButton btn-lg btn-primary" value={translate(this.props.lang, "UPDATE DELIVERY NOTES")} />
					</div> : null}
				</div>
				<div className={(this.state.isEditing === false || !props.deliveryConfig.portalCustomerModifyData ? 'hide' : '') + ' col-12 text-center collapsable-section'}>
					<form onSubmit={this.handleSubmit}>

						<select value={this.state.deliveryNoteSelection} style={{ fontSize: '13px', height: '3rem', marginBottom: '2rem' }} className="input-lg form-control col-12 col-centered" onChange={this.handleDeliveryNoteSelection}>
							<option value="NO_DELIVERY_NOTE">{translate(this.props.lang, 'No delivery note')}</option>
							<option value="LEAVE_FRONT_DOOR">{translate(this.props.lang, 'Please leave at front door')}</option>
							<option value="CALL_ON_ARRIVAL_AFTER_7AM">{translate(this.props.lang, 'Please attempt to call on Arrival if after 7am')}</option>
							<option value="KNOCK_DOOR_AFTER_7AM">{translate(this.props.lang, 'Please ring Doorbell/knock on Door if after 7am')}</option>
							<option value="LEAVE_UNDERCOVER">{translate(this.props.lang, 'Leave Undercover')}</option>
							<option value="LEAVE_OUT_OF_SIGHT">{translate(this.props.lang, 'Please attempt to leave out of sight')}</option>
							<option value="OTHER">{translate(this.props.lang, 'Other - Add comments')}</option>
						</select>

						{this.state.deliveryNoteSelection === 'OTHER' && <div>
							<div className="form-group text-left">
								<label htmlFor="editDeliveryNotes">{translate(this.props.lang, 'Add Comments')}</label>
								<textarea
									type="text"
									placeholder={this.state.editDeliveryNotes}
									className="form-control  col-centered"
									rows="5"
									id="editDeliveryNotes"
									value={this.state.editDeliveryNotes}
									onChange={this.handleCustomDeliveryNoteChange} />
							</div>
						</div>}
						<div className="form-group">
							{this.state.errorMessage ? <p>{this.state.errorMessage}</p> : null}
							{this.state.isSubmitting ? <p>{translate(this.props.lang, 'Sending Data')}</p> : null}
						</div>
						<div className="form-group">
							<button type="button"
								onClick={this.toggleForm.bind(this)}
								className="btn-lg btn-primary btn-cancel semi-block-width">{translate(this.props.lang, 'Cancel')}</button>
							<button
								type="submit"
								disabled={!this.state.hasDeliveryNotesChanged || this.state.isSubmitting}
								style={styles.button}
								onClick={this.submitForm.bind(this)}
								className="trackButton btn-lg btn-primary semi-block-width mt-3 client-btn">{translate(this.props.lang, 'SAVE')}</button>
						</div>
						<p className="text-left">{translate(this.props.lang, 'Please note')}:</p>
						<ul className="text-left">
							<li>{translate(this.props.lang, 'Delivery Note updates need to go through an approval process in our office and we make every effort to get updates to our drivers as soon as posible. If you make an update close the delivery time we can not guarantee the driver will be notified in time.')}</li>
							<li>{translate(this.props.lang, 'Address changes can not be made in this section. Directions on where deliveries can be left at the destination can be.')}</li>
						</ul>
					</form>
				</div>
			</div >);
	}
}

DeliveryDetailsDeliveryNotesForm.contextTypes = {

};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		searchParams: state.searchParams,
		searchValue: state.searchValue,
		item: state.DELIVERY_NOTE_ITEM === undefined || state.DELIVERY_NOTE_ITEM.item === undefined ? null : state.DELIVERY_NOTE_ITEM.item,
		itemAction: state.DELIVERY_NOTE_ITEM === undefined ? null : state.DELIVERY_NOTE_ITEM,
	}
};

const mapDispatchToProps = (dispatch) => ({
	crudActions: bindActionCreators(deliveryActionCreators("DELIVERY_NOTE_ITEM"), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailsDeliveryNotesForm);
