let authenticationURI = '';
let baseURI = '';
let cookieName = 'arc-track';
let s3ImageBase = 'https://images.homedelivery.com.au'
let language = '';
let hostOverride = '';
let customerFrontURI = '';

if ( process.env.REACT_APP_AUTH_URI ) {
    authenticationURI = process.env.REACT_APP_AUTH_URI;
}

if ( process.env.REACT_APP_BASE_URI ) {
    baseURI = process.env.REACT_APP_BASE_URI;
}

if ( process.env.REACT_APP_CUSTOMER_FRONT_URI ) {
    customerFrontURI = process.env.REACT_APP_CUSTOMER_FRONT_URI;
}

if ( process.env.REACT_APP_COOKIE_NAME ) {
    cookieName = process.env.REACT_APP_COOKIE_NAME;
}

if ( process.env.REACT_APP_S3_PATH ) {
    s3ImageBase = process.env.REACT_APP_S3_PATH;
}

if ( process.env.REACT_APP_LANGUAGE ) {
    language = process.env.REACT_APP_LANGUAGE;
}

if ( process.env.REACT_APP_HOST_OVERRIDE ) {
    hostOverride = process.env.REACT_APP_HOST_OVERRIDE;
}

export default {
    apiBase: baseURI,
    authenticationURI: baseURI,
    s3ImageBase: s3ImageBase,
    maps: {
        googleApiKey: "AIzaSyAL4VkJVgs3VGGJ9YfS1TolRNUTt0LT1Lc",
        hereApiKey: "8tz05SX35KzBHFrcmo4WWVocOPKb2eZkzqxgzlEjoJU"
    },
    language: language,
    hostOverride: hostOverride
}
