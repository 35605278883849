import React from 'react';

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, info: null };
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true, error: error, info: info });
	}

	render() {
		if (this.state.hasError) {
			return <div>
				<h1>Something went wrong.</h1>
				<a href="/">Home</a>
				<pre>{this.state.error.stack}</pre>
			</div>;
		}
		return this.props.children;
	}
}