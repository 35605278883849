// https://developer.here.com/documentation/examples/maps-js/markers/markers-on-the-map
// https://developer.here.com/tutorials/react/

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLink } from './useLink';
import { useScript } from './useScript';

import config from 'app/config';

function setStyle(map) {
	// get the vector provider from the base layer
	var provider = map.getBaseLayer().getProvider();
	// Create the style object from the YAML configuration.
	// First argument is the style path and the second is the base URL to use for
	// resolving relative URLs in the style like textures, fonts.
	// all referenced resources relative to the base path https://js.api.here.com/v3/3.1/styles/omv.
	var style = new H.map.Style('https://beta-track.homedelivery.com.au/assets/herestyle.yaml',
		'https://js.api.here.com/v3/3.1/styles/omv/');
	// set the style on the existing layer

	try {
		provider.setStyle(style);
	} catch (e) {
		console.log("FAILED TO SET STYLE", e);
	}
}

const GetHDSTheme = (H, hUi, clusterIcon) => {

	return {
		getClusterPresentation: function (cluster) {
			// Get random DataPoint from our cluster
			const dataPoints = [];
			cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

			const weight = cluster.getWeight();
			// Calculate circle size
			const radius = weight * 5;
			const diameter = radius * 2;
			const svgString = `<svg xmlns="http://www.w3.org/2000/svg" height="${diameter}" width="${diameter}">
                    <circle cx="${radius}px" cy="${radius}px" r="${radius}px" fill="#002bbb" />
                    <text x="50%" y="50%" text-anchor="middle" stroke="#fff" stroke-width="2px" dy=".3em">${weight}</text>
                    </svg>`;

			let randomDataPoint = dataPoints[0];
			// Get a reference to data object that DataPoint holds
			let data = randomDataPoint.getData();

			if (clusterIcon === undefined) {
				clusterIcon = svgString;
			}

			// Create a marker from a random point in the cluster
			let clusterMarker = new H.map.Marker(cluster.getPosition(), {
				icon: new H.map.Icon(clusterIcon, {
					size: { w: diameter, h: diameter },
					anchor: { x: radius, y: radius }
				}),

				// Set min/max zoom with values from the cluster,
				// otherwise clusters will be shown at all zoom levels:
				min: cluster.getMinZoom(),
				max: cluster.getMaxZoom()
			});

			// Link data from the random point from the cluster to the marker,
			// to make it accessible inside onMarkerClick
			clusterMarker.setData(dataPoints);

			return clusterMarker;
		},

		getNoisePresentation: function (noisePoint) {
			// Get a reference to data object our noise points
			let data = noisePoint.getData();
			// Create a marker for the noisePoint
			let noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
				// Use min zoom from a noise point
				// to show it correctly at certain zoom levels:
				min: noisePoint.getMinZoom(),
				icon: new H.map.Icon(data.icon, {
					size: { w: 16, h: 16 },
					anchor: { x: 8, y: 8 }
				})
			});

			// Link a data from the point to the marker
			// to make it accessible inside onMarkerClick
			noiseMarker.setData(data);

			return noiseMarker;
		}
	}
};

const HEREMap = ({ children, lat, lng, zoom, defaultZoom, cluster, clusterIcon, onMarkerDragEnd, ...props }) => {

	const mapRef = React.useRef(null);
	let H = window.H;

	const [,] = useLink('https://js.api.here.com/v3/3.1/mapsjs-ui.css', 'map-styles');//
	const [coreLoaded] = useScript('https://js.api.here.com/v3/3.1/mapsjs-core.js', 'core');//
	const [dataLoaded] = useScript('https://js.api.here.com/v3/3.1/mapsjs-data.js', 'data');
	const [serviceLoaded] = useScript('https://js.api.here.com/v3/3.1/mapsjs-service.js', 'service');//
	const [uiLoaded] = useScript('https://js.api.here.com/v3/3.1/mapsjs-ui.js', 'ui');//
	const [mapeventsLoaded] = useScript('https://js.api.here.com/v3/3.1/mapsjs-mapevents.js', 'mapevents');//
	const [mapclusteringLoaded] = useScript('https://js.api.here.com/v3/3.1/mapsjs-clustering.js', 'clustering');
	const [harpLoaded] = useScript('https://js.api.here.com/v3/3.1/mapsjs-harp.js', 'harp');//

	const [mapD, setMap] = useState({

	});

	useEffect(() => {
		if (!mapD.map || !lat || !lng) {
			return;
		}

		mapD.map.setCenter(new H.geo.Point(lat, lng));

	}, [mapD, lat, lng]);

	useEffect(() => {
		if (!mapD.map || !zoom) {
			return;
		}

		mapD.map.setZoom(zoom);

	}, [mapD, zoom]);

	useEffect(() => {
		if (!(H && coreLoaded && serviceLoaded && uiLoaded && mapeventsLoaded && mapclusteringLoaded && harpLoaded)) {
			return;
		}

		const platform = new H.service.Platform({
			apikey: config.maps.hereApiKey
		});
		
		let defaultLayers = platform.createDefaultLayers();

		let hMap = new H.Map(mapRef.current,
			defaultLayers.vector.normal.map, 
			{
				zoom: 14,
				center: {
					lat: 64.144,
					lng: -21.94,
				},
			}
		);


		const hBehavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));

		const hUi = H.ui.UI.createDefault(hMap, defaultLayers);

		window.hMap = hMap;

		setMap({
			map: hMap,
			behavior: hBehavior,
			ui: hUi
		});

		hMap.addEventListener('dragstart', function (ev) {
			let target = ev.target, pointer = ev.currentPointer;
			if (target instanceof H.map.Marker) {
				let targetPosition = hMap.geoToScreen(target.getGeometry());
				target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
				hBehavior.disable();
			}
		}, false);

		hMap.addEventListener('dragend', function (ev) {
			let target = ev.target;
			if (target instanceof H.map.Marker) {
				hBehavior.enable();

				onMarkerDragEnd && onMarkerDragEnd({
					lat: target.getGeometry().lat,
					lng: target.getGeometry().lng
				});
			}
		}, false);

		hMap.addEventListener('drag', function (ev) {
			let target = ev.target,
				pointer = ev.currentPointer;
			if (target instanceof H.map.Marker) {
				target.setGeometry(hMap.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
			}
		}, false);

		setStyle(hMap);

		return () => {
			hMap.dispose();
		};
	}, [H, mapRef, coreLoaded, serviceLoaded, uiLoaded, mapeventsLoaded, mapclusteringLoaded, harpLoaded]); // This will run this hook every time this ref is updated

	const mapResize = () => {
		if (mapD.map) {
			mapD.map.getViewPort().resize()
		}
	};

	useEffect(() => {
		window.addEventListener('resize', mapResize, true);
		return () => {
			window.removeEventListener('resize', mapResize, true);
		};
	});

	const onMarkerClick = (e) => {

		const hasMaxZoom = e.target.getData().getMaxZoom;
		const currentZoom = mapD.map.getZoom();

		let target = e.target;
		let geo = target.getGeometry();

		mapD.map.getViewModel().setLookAtData({
			zoom: !!hasMaxZoom ? e.target.getData().getMaxZoom() + 2 : currentZoom < 15 ? 15 : currentZoom,
			bounds: new H.geo.Rect(geo.lat, geo.lng, geo.lat, geo.lng)
		});

		// Get position of the "clicked" marker
		let position = target.getGeometry();
		// Get the data associated with that marker
		let data = target.getData();
		// Merge default template with the data and get HTML
		let bubbleContent = data;
		let bubble = onMarkerClick.bubble;

		let popupContent = '';

		if (data.popupContent) {
			popupContent = data.popupContent;
		}

		// console.log( "D", data )
		if (Array.isArray(data)) {
			popupContent = '<div class="popupContainer">';
			data.forEach(d => {
				// console.log( "DD", d.getData() )
				popupContent += d.getData().popupContent;
			});
			popupContent += '</div>';
		}

		// console.log( "PC", popupContent )

		if (!popupContent) {
			return false;
		}

		// console.log( "MARKER CLICKED", target )

		bubbleContent = popupContent;

		// For all markers create only one bubble, if not created yet
		if (!bubble) {
			bubble = new H.ui.InfoBubble(position, {
				content: bubbleContent
			});
			mapD.ui.addBubble(bubble);
			// Cache the bubble object
			onMarkerClick.bubble = bubble;
		} else {
			// Reuse existing bubble object
			bubble.setPosition(position);
			bubble.setContent(bubbleContent);
			bubble.open();
		}

		// Move map's center to a clicked marker
		mapD.map.setCenter(position, true);
	}

	const startClustering = (map, ui, data) => {
		if (H == undefined) {
			return;
		}

		// First we need to create an array of DataPoint objects for the ClusterProvider
		let dataPoints = data.map(function (item) {
			// Note that we pass "null" as value for the "altitude"
			// Last argument is a reference to the original data to associate with our DataPoint
			// We will need it later on when handling events on the clusters/noise points for showing
			// details of that point
			return new H.clustering.DataPoint(item.props.lat, item.props.lng, null, item.props);
		});

		// Create a clustering provider with a custom theme
		let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
			clusteringOptions: {
				// Maximum radius of the neighborhood
				eps: 48,
				// minimum weight of points required to form a cluster
				minWeight: 2
			},
			theme: GetHDSTheme(H, ui, clusterIcon)
		});

		// Note that we attach the event listener to the cluster provider, and not to
		// the individual markers
		clusteredDataProvider.addEventListener('tap', onMarkerClick);

		// Create a layer that will consume objects from our clustering provider
		let layer = new H.map.layer.ObjectLayer(clusteredDataProvider);

		// To make objects from clustering provider visible,
		// we need to add our layer to the map
		map.addLayer(layer);

		return layer;
	}

	let markers = [];


	React.Children.toArray(children).forEach((child, i) => {
		if (mapD.map && mapD.behavior && mapD.ui) {
			markers.push(React.cloneElement(child, {
				H: H,
				hMap: mapD.map,
				hBehavior: mapD.behavior,
				hUi: mapD.ui
			}));
		}
	});

	useEffect(() => {
		if (!H || !mapD.map || lat || lng) {
			return;
		}

		let group = new H.map.Group();

		let boundingMarkers = [];

		React.Children.toArray(children).forEach((child, i) => {
			boundingMarkers.push(new H.map.Marker({ lat: child.props.lat, lng: child.props.lng }));
		});

		group.addObjects(boundingMarkers);

		const bounds = group.getBoundingBox();

		if (bounds) {

			const PADDING = 0.001;

			bounds.ca = bounds.ca > 0 ? bounds.ca - PADDING : bounds.ca + PADDING;
			bounds.ga = bounds.ga > 0 ? bounds.ga + PADDING : bounds.ga - PADDING;
			bounds.ka = bounds.ka > 0 ? bounds.ka - PADDING : bounds.ka + PADDING;
			bounds.ma = bounds.ma > 0 ? bounds.ma + PADDING : bounds.ma - PADDING;

			mapD.map.getViewModel().setLookAtData({
				bounds: bounds
			}, true);
		}

		group.removeObjects(boundingMarkers);
		group = null;

	}, [lat, lng, children, mapD.map]);

	useEffect(() => {

		let layer = null;

		if (H && mapD.map && cluster) {
			layer = startClustering(mapD.map, mapD.ui, markers);
			// console.log( "SC", markers )
		}

		return () => {
			try {
				mapD.map.removeLayer(layer);
			} catch (e) {
			}
		};


	}, [cluster, markers]);


	const markerArray = (cluster) ? [] : markers;

	return <div className="map" ref={mapRef} style={{ height: props.mapHeight || "500px", width: '100%' }}>
		{mapD.map ? markerArray : null}
	</div>;
};

const HEREMarker = ({ H, hMap, hBehavior, hUi, lat, lng, ...rest }) => {
	const [marker, setMarker] = useState(undefined);

	function addCircleToMap(map) {
		map.addObject(new H.map.Circle(
			// The central point of the circle
			{ lat, lng },
			// The radius of the circle in meters
			500,
			{
				style: {
					strokeColor: 'rgba(0, 100, 255, 0.5)', // Color of the perimeter
					lineWidth: 2,
					fillColor: 'rgba(0, 155, 255, 0.3)'  // Color of the circle
				}
			}
		));
	}

	useEffect(() => {
		if (hMap && !marker) {
			let iconData = {};
			if (rest.icon) {
				iconData.icon = new H.map.Icon(rest.icon);
			}

			if (rest.draggable) {
				iconData.volatility = true;
			}

			let newMarker = new H.map.Marker({ lat: lat, lng: lng }, iconData);

			if (rest.popupContent) {
				newMarker.setData(rest.popupContent);

				newMarker.addEventListener('tap', (evt) => {
					let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
						content: evt.target.getData()
					});

					hUi.addBubble(bubble);
				}, false);
			}

			if (rest.draggable) {
				newMarker.draggable = true;
			}

			addCircleToMap(hMap);
			// hMap.addObject( newMarker ); // replace specific house marker with wide circle
			setMarker(newMarker);
		}


		return () => {
			if (hMap && marker) {
				try {
					setMarker(undefined);
					hMap.removeObjects([marker]);
				} catch (e) {
					// console.log("FAILED TO REMOVE MARKER", marker, e);
				}
			}
		};
	}, [lat, lng, hMap, marker]);

	return null;
};

export { HEREMap, HEREMarker }