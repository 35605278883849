import _forEach from 'lodash-es/forEach';
import _isFunction from 'lodash-es/isFunction';
import { checkHttpStatus, parseJSON } from 'io-redux/http';
import axios from 'axios';
import config from 'app/config';

const standardHeaders = (token) => {

	let headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	};

	if (token !== undefined && token !== null) {
		headers['Authorization'] = 'Bearer: ' + token;
	}

	return headers;
};

const crudQuery = (parsePath, onReceive) => {
	return (token, params) => {
		return axios.get(`${parsePath(null, params, token)}`, {
			params: params,
			headers: standardHeaders(token)
		}).then(checkHttpStatus).then(parseJSON).then(onReceive)
	};
};

const crudList = (parsePath, onReceive) => {
	return (token, params) => {
		return axios.get(`${parsePath(null, params, token)}`, {
			params: params,
			headers: standardHeaders(token)
		}).then(checkHttpStatus).then(parseJSON).then(resp => {

			let itemsOR = [];

			resp.data.forEach(x => {
				itemsOR.push(onReceive(x));
			});

			resp.data = itemsOR;

			return resp;
		})
	};
};

const crudGet = (parsePath, onReceive) => {
	return (id, token, params) => {
		return axios.get(`${parsePath(id, params, token)}/${id}`, {
			params: params,
			headers: standardHeaders(token)
		}).then(checkHttpStatus).then(parseJSON).then(onReceive)
	};
};

const crudSingle = (parsePath, onReceive) => {
	return (token, params) => {
		return axios.get(`${parsePath(null, params, token)}`, {
			params: params,
			headers: standardHeaders(token)
		}).then(checkHttpStatus).then(parseJSON).then(onReceive)
	};
};

const crudDel = (parsePath) => {
	return (id, token, params) => {
		return axios.delete(`${parsePath(id, params, token)}/${id}`, {
			headers: standardHeaders(token)
		});
	};
};

const crudPatch = (parsePath, onSend, onReceive) => {
	return (id, data, token, params) => {
		return axios.patch(`${parsePath(id, params, token)}/${id}`, onSend(data), {
			headers: standardHeaders(token)
		}).then(checkHttpStatus).then(parseJSON).then(onReceive);
	};
};

const crudCreate = (parsePath, onSend, onReceive) => {
	return (data, token, params) => {
		return axios.post(`${parsePath(null, params, token)}`, onSend(data), {
			headers: standardHeaders(token)
		}).then(checkHttpStatus).then(parseJSON).then(onReceive);
	};
};

const crudUpdate = (parsePath, onSend, onReceive) => {
	return (id, data, token, params) => {
		if (id == null) {
			return axios.put(`${parsePath(id, params, token)}`, onSend(data), {
				headers: standardHeaders(token)
			}).then(checkHttpStatus).then(parseJSON).then(onReceive);
		}
		return axios.put(`${parsePath(id, params, token)}/${id}`, onSend(data), {
			headers: standardHeaders(token)
		}).then(checkHttpStatus).then(parseJSON).then(onReceive);
	};
};

const crudUpload = (parsePath, onReceive) => {
	return (id, file, token, params) => {
		const fd = new FormData();
		fd.append('id', id);
		fd.append('file', file);

		return axios.post(`${parsePath(null, params, token)}/${id}/file`, fd, {
			headers: {
				'Authorization': 'Bearer: ' + token
			}
		}).then(checkHttpStatus);
	};
};


const crudSave = (parsePath, onSend, onReceive) => {
	return (data, token, params) => {
		if (data.id === undefined) {
			return crudCreate(parsePath, onSend, onReceive)(data, token, params);
		} else {
			return crudUpdate(parsePath, onSend, onReceive)(data.id, data, token, params);
		}
	};
};

export default function rest(path, onReceive, onSend) {

	const parsePath = (id, params, token) => {
		let newPath = path;


		if (_isFunction(newPath)) {
			newPath = newPath(id, params, token);
		}

		newPath = config.apiBase + "/" + newPath;

		_forEach(params, function (v, k) {
			newPath = newPath.replace('{' + k + '}', v);
		});

		return newPath;
	};


	return {
		get: crudGet(parsePath, onReceive),
		single: crudSingle(parsePath, onReceive),
		query: crudQuery(parsePath, onReceive),
		list: crudList(parsePath, onReceive),
		patch: crudPatch(parsePath, onSend, onReceive),
		create: crudCreate(parsePath, onSend, onReceive),
		update: crudUpdate(parsePath, onSend, onReceive),
		upload: crudUpload(parsePath, onSend, onReceive),
		save: crudSave(parsePath, onSend, onReceive),
		del: crudDel(parsePath, onSend, onReceive)
	}
}