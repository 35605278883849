import rest from 'io-redux/crud/rest';
import actionCreatorsFor from 'io-redux/crud/actionCreatorsFor';
import reducersFor from 'io-redux/crud/reducersFor';


export const onReceive = (data) => {

	let bgc1 = data.data.data.portalHeaderBackgroundColour1 || '';
	let bgc2 = data.data.data.portalHeaderBackgroundColour2 || bgc1;
	let gradientDirection = 'right';

	const hdc1 = data.data.data.portalHeadingColour || 'fff';
	const hlc1 = data.data.data.portalHighlightColour;
	const bc1 = data.data.data.portalButtonColour;

	if (bgc1 === '') {
		bgc1 = '00ADEE';
		bgc2 = '002BBB';
		gradientDirection = 'bottom';
	}

	data.data.data.clientStyles = {
		header: {
			background: `linear-gradient(to ${gradientDirection}, #${bgc1} 0%,#${bgc2} 100%), #${bgc1}`,
			color: `#${hdc1}`,
			filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='#${bgc1}', endColorstr='#${bgc2}',GradientType=1 )`
		},
		textHighlight: {
			color: `#${hlc1}`,
		},
		ratingHighlight: {
			fill: `#${hlc1}`,
			stroke: `#${hlc1}`,
		},
		rating: {
			fill: `#888`,
			stroke: `#888`,
		},
		button: {
			backgroundColor: `#${bc1}`,
			borderColor: `#${bc1}`,
		}
	}
	return data;
};

const onSend = (data) => {
	return data;
};

const path = (id, params, token) => {
	return "api/ClientConfig/GetByDomain";
};

export function actionCreators(storePath, types) {
	return actionCreatorsFor(storePath, types, rest(path, onReceive, onSend), {
		hasMeta: false,
	});
}

export function reducers(storePath, types) {
	return reducersFor(storePath, types);
}