import React, { Component } from 'react';
import { addDays, format as formatDate } from 'date-fns';
import DeliveryDetailsMap from "./DeliveryDetailsMap";
import DeliveryDetailsTimelineItem from './DeliveryDetailsTimelineItem';
import DeliveryDetailsAddress from './DeliveryDetailsAddress';
import {filterScanLog, groupScanLogByStatus} from 'common/filterScanLog';
import { translate } from "../../../common/translations";

class DeliveryDetailsPending extends Component {

	render() {

		const props = this.props;

		const delivery = this.props.delivery;
		const deliveryConfig = this.props.deliveryConfig;
		const styles = deliveryConfig.clientStyles;
		const { boxes, scanLog } = delivery;

		const scanLogFiltered = filterScanLog(scanLog, boxes);
		const scanLogGrouped = groupScanLogByStatus(scanLogFiltered);

		const hasScanIncoming = scanLog.find(s => s.status == 'incoming');
		const hasScanPickup = scanLog.find(s => s.status == 'transfer' || s.status == 'pickup');

		const firstScan = [...scanLog?.sort((a,b) => new Date(b.scannedDt) - new Date(a.scannedDt))].pop();

		let eta = '';

		if ( firstScan ){
			let etaStart = addDays(new Date(firstScan.scannedDt), 7);
			let etaEnd = addDays(new Date(firstScan.scannedDt), 10);
			eta = ` ${formatDate(etaStart, "DD MMM")} - ${formatDate(etaEnd, "DD MMM")}`;
		}

		return (
			<div className="deliveryDetails row">
				<div className="col-12">
					<div className="row division white-bg">
						<div className="col-12 text-center">
							<h3>
							{!scanLogFiltered.length && translate(
								this.props.lang,
								"Awaiting Update from Supplier"
							)}
							{scanLogFiltered.length > 0 && hasScanPickup && !hasScanIncoming && 
							<>
								{translate(
									this.props.lang,
									"Order being Processed"
								)}
							</>
							}
							{scanLogFiltered.length > 0 && (hasScanIncoming || hasScanPickup) && 
								<div>
									{deliveryConfig.portalShowPredeliveryEta ? <>
										{eta !== '' && translate(
											this.props.lang,
											"Expect Delivery between"
										)} {eta}
									</> : <>
										{translate(
											this.props.lang,
											"Order being Processed"
										)}
									</>}
								</div>
							}
							</h3>
						</div>
					</div>
					<DeliveryDetailsMap
						lang={props.lang}s
						latitude={delivery.latitude}
						longitude={delivery.longitude}
						deliveryConfig={deliveryConfig}
					/>

					{boxes &&
						<DeliveryDetailsAddress
							lang={props.lang}
							delivery={delivery}
							styles={styles}
							boxes={boxes}
						/>
					}

					{scanLogFiltered && scanLogFiltered.length > 0 &&
						<div className="row pt-2 px-4 division white-bg timeline-container">
							{
								Object.keys(scanLogGrouped).map((key) => (
									<DeliveryDetailsTimelineItem lang={props.lang}
										key={[...scanLogGrouped[key]].pop().scannedDt}
										styles={styles}
										data={[...scanLogGrouped[key]].pop()}
										deliveryStatus={delivery.deliveryStatus}
										boxScans={scanLogGrouped[key]}
									/>
								))
							}
						</div>
					}

					<p className="row">&nbsp;</p>
				</div>
			</div>
		);
	}
}

export default DeliveryDetailsPending
