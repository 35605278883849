import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as searchParamsAC from 'module/authentication/action/SearchParams';
import Cookies from 'universal-cookie';
import TagManager from 'react-gtm-module';

import { Switch, Route, Redirect } from 'react-router-dom';

import CustomerSearch from './module/customersearch/Route';

const cookies = new Cookies();

const NotFound = (props) => {
	return (
		<div className="">
			<div className="">
				<h1>Route Not Found</h1>
			</div>
			<div className="">
			</div>
		</div>
	)
};


const tagManagerArgs = {
    gtmId: 'GTM-KCVKVBCG',
    dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs);

class SiteContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notificationShow: false
		}
	}

	componentDidMount() {
		// this.props.vtActions.verifyToken(cookies.get('ioToken'));
		this.props.spActions.decodeSearchParams(this.props);
	}

	componentWillReceiveProps(nextProps) {

		if (this.props.location.search != nextProps.location.search) {
			this.props.spActions.decodeSearchParams(nextProps);
		}

	}

	toggleNotificationPanel() {
		this.setState({
			notificationShow: !this.state.notificationShow
		})
	}

	render() {

		const props = this.props;

		return (
			<div>
				<Switch>
					<Route exact path="/" component={CustomerSearch} />
					<Route path="/track" component={CustomerSearch} />
					<Route component={NotFound} />
				</Switch>
			</div>
		);
	}
};

SiteContainer.contextTypes = {

};

const mapStateToProps = (state) => ({
	auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
	// vtActions: bindActionCreators(verifyTokenAC, dispatch),
	spActions: bindActionCreators(searchParamsAC, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteContainer);