import { applyMiddleware, createStore, compose } from 'redux';
import createReducer from './reducers';
import thunk from 'redux-thunk';
import reducerRegistry from '../lib/reducerRegistry';

let store = null;

export function configureStore() {

	// console.log( "CONFIGURE STORE" )

	store = createStore(createReducer(),
		applyMiddleware(thunk)
	);
	store.asyncReducers = {};

	return store;
}

export function injectAsyncReducer(name, asyncReducer) {
	reducerRegistry.register(name, asyncReducer);
}