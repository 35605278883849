import React, { Component } from 'react';
import DeliveryDetailsPending from './DeliveryDetailsPending'
import DeliveryDetailsDelivered from './DeliveryDetailsDelivered'
import PredeliveryDetails from './PredeliveryDetails';
import { translate } from '../../../common/translations';

class DeliveryDetails extends Component {

	constructor(props) {
		super(props);
	}

	handleRefresh(e) {
		e.preventDefault();
		this.props.handleRefresh();
	}

	render() {
		const props = this.props;
		const styles = props.deliveryConfig.clientStyles;

		var refresh = <div className="searchform row">
			<div className="col-12">
				<form onSubmit={this.handleRefresh.bind(this)}>
					<div className="form-group">
						<button disabled={props.loading} type="submit" style={styles.button} className="trackButton btn-lg btn-primary client-btn" >{props.loading ? translate(props.lang, "LOADING") : translate(props.lang, "REFRESH")}</button>
					</div>
				</form>
			</div>
		</div>

		// Conditionally render components dependant on delivery status
		if (props.delivery.deliveryStatus === 'Completed' || props.delivery.deliveryStatus === 'Delivered' || props.delivery.deliveredDt) {
			return (
				<>
					<DeliveryDetailsDelivered lang={props.lang}
						delivery={props.delivery}
						deliveryConfig={props.deliveryConfig}
						clientEmail={props.clientEmail}
					/>
				</>
			);

		} else if (props.delivery.deliveryDate) {
			return (
				<>
					{refresh}
					<DeliveryDetailsPending lang={props.lang}
						delivery={props.delivery}
						deliveryConfig={props.deliveryConfig}
						refresh={refresh}
					/>
				</>
			);

		} else if (props.delivery) {
			return (
				<>
					{refresh}
					<PredeliveryDetails lang={props.lang}
						delivery={props.delivery}
						deliveryConfig={props.deliveryConfig}
            refresh={ refresh }
					/>
				</>
			);
		}
	}
}

export default DeliveryDetails;
