import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducerRegistry from './lib/reducerRegistry';

import searchParamsReducer from './module/authentication/reducers/SearchParams';

export const isServer = !(
	typeof window !== 'undefined' &&
	window.document &&
	window.document.createElement
);

export default (url = '/') => {

	const enhancers = [];

	if (process.env.NODE_ENV === 'development' && !isServer) {
		const devToolsExtension = window.devToolsExtension;

		if (typeof devToolsExtension === 'function') {
			enhancers.push(devToolsExtension());
		}
	}

	const middleware = [thunk];
	const composedEnhancers = compose(
		applyMiddleware(...middleware),
		...enhancers
	);

	// Do we have preloaded state available? Great, save it.
	const initialState = isServer || window.__PRELOADED_STATE__ === undefined ? {} : window.__PRELOADED_STATE__;

	// Delete it once we have it stored in a variable
	if (!isServer) {
		if (window.__PRELOADED_STATE__) {
			window.initialRenderDone = true;
		}
		delete window.__PRELOADED_STATE__;
	}

	const combine = (reducers) => {
		const reducerNames = Object.keys(reducers);
		Object.keys(initialState).forEach(item => {
			if (reducerNames.indexOf(item) === -1) {
				reducers[item] = (state = null) => state;
			}
		});

		reducers['searchParams'] = searchParamsReducer;

		return combineReducers(reducers);
	};

	let appReducer = combine(reducerRegistry.getReducers());
	const rootReducer = (state, action) => {
		if (action.type === 'LOGOUT_USER_SUCCESS') {
			state = {
				auth: {
					didAttemptTokenVerification: true,
					currentUser: null,
					token: null
				}
			}
		}
		return appReducer(state, action)
	}

	// Create the store
	const store = createStore(
		rootReducer,
		initialState,
		composedEnhancers
	);

	reducerRegistry.setChangeListener(reducers => {
		appReducer = combine(reducers);
		store.replaceReducer(rootReducer);
	});

	return {
		store
	};
};
