import config from 'app/config';

let translations = {
	en_AU: {

	},
	en_US: {
		"POSTCODE": "ZIP CODE"
	},
	nl_NL: {
		Carrier: "Vervoerder",
		"Delivery Status": "Bezorgstatus",
		REFRESH: "VERVERSEN",
		Refresh: "Verversen",
		"Delivery Details": "BEZORGGEGEVENS",
		Address: "ADRES",
		"Order Number": "BESTELNUMMER",
		Boxes: "PAKKETTEN",
		"Delivery Notes": "NOTITIES BEZORGING",
		"Driver's Notes": "NOTITIES BEZORGER",
		"No driver note": "Geen notities bezorger",
		"Delivery Photos": "FOTO’S BEZORGING",
		"How was your experience?": "HOE WAS JE ERVARING?",
		Submit: "Versturen",
		SUBMIT: "VERSTUREN",
		"Your Delivery Is Estimated To Arrive": "JE PAKKET WORDT DOOR SPOONS ON WHEELS BEZORGD TUSSEN",
		"Contact Details": "CONTACTGEGEVENS",
		"Your delivery was unable to be delivered": "Uw levering kon niet worden afgeleverd",
		"Out for Delivery": "Uit voor levering",
		"Sorted & ready for dispatch at warehouse": "Gesorteerd en klaar voor verzending in het magazijn",
		"Re-sort": "Opnieuw sorteren",
		"Awaiting Update from Supplier": "Wacht op update van leverancier",
		"Order being Processed": "Bestelling wordt verwerkt",
		"Expect Delivery between": "Verwachte levering tussen",
		"Ready for Pickup from Supplier" : "Klaar voor ophalen bij leverancier",
		"Received to refrigerated warehouse" : "Ontvangen in gekoelde opslag",
		"Ready for Dispatch" : "Klaar voor verzending",
		"Driver - On Vehicle for Delivery" : "Bestuurder - Op voertuig voor levering",
		"Driver - Scanned at Delivery Location" : "Bestuurder - Gescand op leverlocatie",
		"Don't want to receive SMS messages?" : "Wil je geen SMS-berichten ontvangen?",
		"If you prefer not to receive further SMS messages from us, you can unsubscribe easily." : "Als u liever geen sms-berichten meer van ons ontvangt, kunt u zich eenvoudig afmelden.",
		"Thank you, your SMS preference has been submitted." : "Bedankt, uw SMS-voorkeur is verzonden.",
	},
	de_DE: {
		Carrier: "Spediteur",
		"Delivery Status": "Lieferstatus",
		REFRESH: "AKTUALISIEREN",
		Refresh: "aktualisieren",
		"Delivery Details": "Lieferdetails",
		Address: "Adresse",
		"Order Number": "Bestellnummer",
		Boxes: "Pakete",
		"Delivery Notes": "Lieferhinweise",
		"Driver's Notes": "Lieferschein",
		"No driver note": "Kein Lieferschein",
		"Delivery Photos": "Fotos der Lieferung",
		"How was your experience?": "Wie war Ihre Erfahrung?",
		Submit: "absenden",
		SUBMIT: "ABSENDEN",
		"Contact Details": "Kontaktdetails",
		"Your Delivery Is Estimated To Arrive": "Ihre Lieferung kommt voraussichtlich",
		"Your delivery was unable to be delivered": "Ihre Lieferung konnte nicht zugestellt werden",
		"Out for Delivery": "raus zur Lieferung",
		"Sorted & ready for dispatch at warehouse": "Sortiert & versandfertig im Lager",
		"Re-sort": "Sortieren erneut",
		"Awaiting Update from Supplier": "Warten auf Update vom Lieferanten",
		"Order being Processed": "Bestellung wird verarbeitet",
		"Expect Delivery between": "Erwarte Lieferung zwischen",
		"Ready for Pickup from Supplier" : "Bereit für Abholung vom Lieferanten",
		"Received to refrigerated warehouse" : "Empfangen in gekühltem Lager",
		"Ready for Dispatch": "Bereit für Versand",
		"Driver - On Vehicle for Delivery": "Fahrer - Auf Fahrzeug für Lieferung",
		"Driver - Scanned at Delivery Location": "Fahrer - Gescannt an Lieferort",
		"Don't want to receive SMS messages?" : "Sie möchten keine SMS-Nachrichten erhalten?",
		"If you prefer not to receive further SMS messages from us, you can unsubscribe easily." : "Wenn Sie keine weiteren SMS-Nachrichten mehr von uns erhalten möchten, können Sie sich ganz einfach abmelden.",
		"Thank you, your SMS preference has been submitted." : "Vielen Dank, Ihre SMS-Präferenz wurde übermittelt.",
	}
};


export const translate = (language, variable) => {
	if (!translations[language]) {
		return variable;
	}

	if (!translations[language][variable]) {
		return variable;
	}

	return translations[language][variable];
}