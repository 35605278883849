function addGroup(resource, actionTypes, type) {
	let upperResource = resource.toUpperCase();
	let upperType = type.toUpperCase();

	let clear = upperResource + '_' + upperType + '_CLEAR';
	let request = upperResource + '_' + upperType + '_REQUEST';
	let refresh = upperResource + '_' + upperType + '_REQUEST_REFRESH';
	let failure = upperResource + '_' + upperType + '_FAILURE';
	let success = upperResource + '_' + upperType + '_SUCCESS';
	let complete = upperResource + '_' + upperType + '_COMPLETE';

	actionTypes[clear] = clear;
	actionTypes[request] = request;
	actionTypes[failure] = failure;
	actionTypes[success] = success;
	actionTypes[complete] = complete;
	actionTypes[refresh] = refresh;
}

export default function actionTypesFor(resource, types) {
	if (resource == null || resource == '') throw new Error('Expected resource');

	let actionTypes = {};

	types.forEach(type => {
		addGroup(resource, actionTypes, type);
	});

	return actionTypes;
}