import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string'

import CustomerSearchForm from '../components/CustomerSearchForm';
import DeliveryNotFound from '../components/DeliveryNotFound';
import DeliveryDetails from '../components/DeliveryDetails';

import config from 'app/config';

import { injectAsyncReducer } from 'io-redux/store';

import { reducers as deliveryReducers, actionCreators as deliveryActionCreators } from '../resource/delivery';
import { reducers as configReducers, actionCreators as configActionCreators, onReceive } from '../resource/clientconfig';
import { translate } from '../../../common/translations';
import DeliveryCancelled from '../components/DeliveryCancelled';
injectAsyncReducer('CUSTOMERSEARCH_DB_DELIVERY_LIST', deliveryReducers("CUSTOMERSEARCH_DB_DELIVERY_LIST", ["GET"]));
injectAsyncReducer('CLIENT_CONFIG', configReducers("CLIENT_CONFIG", ["GET"]));

class DeliveryPage extends Component {

	constructor(props) {
		super(props);
		const values = queryString.parse(this.props.location.search)
		this.clientEmail = values.email != undefined ? values.email : null;
		this.defaultConfig = {
			PortalHeaderBackgroundColour1: 'ef4d3b'
		}

		this.state = {
			contactFormHasSubmitted: false,
			deliveryNotesFormHasSubmitted: false,
			lang: config.language || 'en_AU',
			previewUpdates: 0,
			clientStyles: {},
			clientConfig: {},
			previewIsDelivered: false,
			previewRoundHasStarted: false,
			isLoadedFromWindowObject: false,
			hasLoadedSpecial: false
		}

		this.receivedPreviewUpdate = this.receivedPreviewUpdate.bind(this);

		window.addEventListener('message', (event) => {
			if (event.data.previewValues) {
				let newValues = {
					data: {
						data: event.data.previewValues.configuration
					}
				};

				this.receivedPreviewUpdate(onReceive(newValues));

				let currentShowAsDelivered = this.state.previewIsDelivered;
				let currentShowAsRoundHasStarted = this.state.previewRoundHasStarted;

				if (currentShowAsDelivered !== event.data.previewIsDelivered) {
					this.setState({
						previewIsDelivered: event.data.previewIsDelivered
					});

					let deliveryCode = props.match.params.reference;
					let postCode = props.match.params.postcode;

					this.handleSubmit(deliveryCode, postCode);
				}

				if (currentShowAsRoundHasStarted !== event.data.previewRoundHasStarted) {
					this.setState({
						previewRoundHasStarted: event.data.previewRoundHasStarted
					});


					let deliveryCode = props.match.params.reference;
					let postCode = props.match.params.postcode;

					this.handleSubmit(deliveryCode, postCode);
				}

			}
		}, false);
	}

	handleSubmit(deliveryCode, postcode = null) {
		if (deliveryCode === null || deliveryCode === undefined || deliveryCode === '' || postcode === '') {
			return;
		}

		let newlocation = '';

		if (postcode !== null) {
			newlocation = `/track/${postcode}/${encodeURIComponent(deliveryCode)}`
		} else {
			newlocation = `/track/client/${encodeURIComponent(deliveryCode)}`
		}

		if (this.props.location.pathname != newlocation) {
			this.props.history.push(newlocation);
		} else {
			this.props.crudActions.clear();
			this.handleRefresh(this.props);
		}
	}

	handleRefresh(props) {

		/*
			window.hds is injected in the PublicApi for sites that don't redirect to a tracking subdomain
			Ex:  test.homedeliveery.com.au   window.hds is undefined
			Ex:  hds.gg/Aa12345              window.hds is defined with the delivery details and client config
		*/
		if (window.hds !== undefined) {
			if (!this.state.isLoadedFromWindowObject) {

				props.loadDataFromLocalObject(window.hds);

				this.setState({
					isLoadedFromWindowObject: true
				});

				return;
			} else {
				window.location.reload();
			}
		}


		let includeClientConfig = window.hds !== undefined;
		if (window.location.hostname.startsWith('beta-track.') || window.location.hostname.startsWith('track.') || window.location.hostname === 'localhost' || window.location.hostname.endsWith('hds.gg')) {
			includeClientConfig = true;
		}

		if (props.match.params.reference) {
			const options = {
				clientId: props.config ? props.config.data.clientId : null,
				postcode: props.match.params.postcode === undefined ? null : props.match.params.postcode,
				trackingHash: props.match.params.trackingHash === undefined ? null : props.match.params.trackingHash,
				includeClientConfig: includeClientConfig,
				previewIsDelivered: this.state.previewIsDelivered
			};

			props.crudActions.get(props.match.params.reference, null, options);
		} else if (props.match.params.trackingHash) {

			const options = {
				clientId: props.config ? props.config.data.clientId : null,
				includeClientConfig: includeClientConfig
			};

			props.crudActions.get('hash/' + props.match.params.trackingHash, null, options);
		}


	}

	componentDidMount() {
		const props = this.props;
		let hostname = window.location.hostname;

		if (config.hostOverride) {
			hostname = config.hostOverride;
		}

		if (this.props.searchParams.hostName) {
			let domains = this.props.searchParams.hostName.split(',').filter(i => i !== '');
			if (domains.length > 0) {
				hostname = domains[0];
			}
		}

		this.props.crudActions.clear();

		this.handleRefresh(this.props);

		// Refresh data every 5 minutes
		this.timerID = setInterval(
			() => this.handleRefresh(props),
			300000
		);

		//use track.homedelivery as we treat track.us and track.nl differently
		if (window.location.hostname.startsWith('beta-track.') || window.location.hostname.startsWith('track.') || window.location.hostname === 'localhost' || window.location.hostname.endsWith('hds.gg')) {
			return;
		}

		this.props.configCrudActions.get(encodeURIComponent(hostname), null, {});
	}

	componentWillUnmount() {
		// tear down timer
		clearInterval(this.timerID);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.previewUpdates != this.state.previewUpdates || (nextProps.configAction.hasRequested === true && this.props.config === null) || nextProps.itemAction.isRequestingItem === true || nextProps.itemAction.hasRequested === true) {    // got new item info
			return true;
		}
		return false;
	}

	componentWillReceiveProps(nextProps, nextState) {
		const self = this;

		if (nextProps.configAction.hasRequested === true && nextProps.match.params.reference !== undefined && nextProps.itemAction.item === undefined) {
			return;
		}

		if (nextProps.item?.data?.clientConfig && !this.props.item?.data?.clientConfig) {
			this.setState({
				clientConfig: nextProps.item.data.clientConfig,
				clientStyles: onReceive({
					data: {
						data: nextProps.item.data.clientConfig
					}
				}).data.data.clientStyles
			});
		}

		if (nextProps.searchParams.lang && nextProps.searchParams.lang !== this.props.searchParams.lang) {
			this.setState({
				lang: nextProps.searchParams.lang
			})
		}

		if (this.props.location.pathname !== nextProps.location.pathname && nextProps.match.params.reference !== undefined) {
			this.props.crudActions.clear();
			this.handleRefresh(nextProps);
			return;
		}

		if (nextProps?.config?.data && !this.state.hasLoadedSpecial && !this.props.item && !(nextProps.itemAction.isRequestingItem === true || nextProps.itemAction.hasRequested === true)) {
			setTimeout(function () {
				self.handleRefresh(nextProps);

				self.setState({
					...this.state,
					hasLoadedSpecial: true
				});
			}, 100);
			return;
		}
	}

	receivedPreviewUpdate(data) {
		this.setState({
			previewUpdates: this.state.previewUpdates + 1,
			clientStyles: data.data.data.clientStyles,
			clientConfig: data.data.data
		})
	}

	render() {
		const props = this.props;
		const title = translate(this.state.lang, 'Delivery Status');
		const content = [];

		if (window.location.hostname.startsWith('beta-track.') || window.location.hostname.startsWith('track.') || window.location.hostname === 'localhost' || window.location.hostname.endsWith('hds.gg')) {

		} else if (props.config === null) {
			const statusMessage = props.configError ? 'Unable to load site' : 'Loading';
			return (
				<div className="trackingapp">
					<div className="page">
						<div className="loadingHeader row">
							<div className="logo col-6"></div>
							<div className="title col-6">{title}</div>
						</div>
						<div className="m-3 h2 text-center">{statusMessage}</div>
						<div className="m-3 text-center"><img src="/img/loadinganimate.gif" /></div>
					</div>
				</div>
			)
		}

		let configData = props.config ? props.config.data : {
			clientStyles: {
				header: {
					background: 'linear-gradient(to right, #bf0d85 0%,#ef4d3b 100%)'
				}
			},
			showDeliveryDetails: true,
			hdsLogo: 'poweredbyhds.png'
		};

		let styles = { ...configData.clientStyles, ...this.state.clientStyles };
		const mergedConfig = { ...configData, clientStyles: styles, ...this.state.clientConfig };

		// show search form if no items
		if (props.item == null) {
			content.push(
				<CustomerSearchForm
					lang={this.state.lang}
					key="CustomerSearchForm"
					deliveryConfig={mergedConfig}
					handleSubmit={this.handleSubmit.bind(this)}
					searchValue={props.match.params.reference !== undefined ? decodeURIComponent(props.match.params.reference) : ''}
					searchPostcode={props.match.params.postcode !== undefined ? props.match.params.postcode : ''}
					searchTrackingHash={props.match.params.trackingHash !== undefined ? props.match.params.trackingHash : ''}
					loading={props.itemAction.isRequestingItem}
				/>
			);
		}

		if (props.itemError && props.item === null) {
			content.push(
				<DeliveryNotFound lang={this.state.lang} key="DeliveryDetailsNotFound" />
			);
		} else {
			if (props.item !== null && props.item.data.isCancelled) {
				content.push(
				<DeliveryCancelled />
				);
			}
		else if (props.item !== null) {
			let deliveryData = {...props.item.data};

				if ( this.state.previewRoundHasStarted ){
					deliveryData = {...deliveryData, hasRoundStarted: true, numberBefore: 5};
				}

				content.push(
					<DeliveryDetails
						lang={this.state.lang}
						key="DeliveryDetails"
						handleRefresh={this.handleRefresh.bind(this, props)}
						delivery={deliveryData}
						deliveryConfig={mergedConfig}
						clientEmail={this.clientEmail}
						loading={props.itemAction.isRequestingItem}
						previewUpdate={this.receivedPreviewUpdate}
					/>
				);
			}
		}

		let clientLogo = mergedConfig.portalClientLogo && mergedConfig.portalClientLogo.startsWith('http') ? mergedConfig.portalClientLogo : `${config.s3ImageBase}/client/${mergedConfig.clientId}/${mergedConfig.portalClientLogo}`;

		let advertisingImage = mergedConfig.portalAdvertisingImage && mergedConfig.portalAdvertisingImage.startsWith('http') ? mergedConfig.portalAdvertisingImage : `${config.s3ImageBase}/client/${mergedConfig.clientId}/${mergedConfig.portalAdvertisingImage}`;

		if (mergedConfig.portalClientLogo && mergedConfig.portalClientLogo.startsWith('client')) {
			clientLogo = `${config.s3ImageBase}/${mergedConfig.portalClientLogo}`;
		}

		const adImg = mergedConfig.portalAdvertisingImage && mergedConfig.showAdvertising ? advertisingImage : null;
		const adUrl = mergedConfig.portalAdvertisingImageLink;

		return (
			<div className="trackingapp">
				<div className="page">
					<div className="clientHeader row" style={styles.header}>
						<div className="col-12 text-center d-flex align-items-center justify-content-between">
							{mergedConfig.portalClientLogo && (
								<img style={{ maxHeight: '60px', float: 'left', maxWidth: '75px' }} src={clientLogo} />
							)}
							<span style={{ position: 'absolute', left: 0, right: 0, fontSize: '1rem' }}>{title}</span>
							<a href="https://www.homedelivery.com.au/" className="d-block" style={{maxWidth: '75px'}}>
								<img style={{ width: '100%', height: 'auto' }} className="mr-4" src={`https://tracking.homedelivery.com.au/img/${mergedConfig.hdsLogo && mergedConfig.hdsLogo !== '' ? mergedConfig.hdsLogo : 'poweredbyhds.png'}?fr`} />
							</a>
						</div>
					</div>

					{adImg != null ? <div className="client-ad row justify-content-center" style={{ display: 'block' }}>
						{adUrl && <a href={adUrl}><img src={adImg} style={{ maxWidth: '100%' }} /></a>}
						{!adUrl && <img src={adImg} style={{ maxWidth: '100%' }} />}
					</div> : null}

					{content}

				</div>
			</div>
		);
	}
}

DeliveryPage.contextTypes = {

};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		searchParams: state.searchParams,
		searchValue: state.searchValue,
		itemError: !!!state.CUSTOMERSEARCH_DB_DELIVERY_LIST.error ? false : true,
		item: state.CUSTOMERSEARCH_DB_DELIVERY_LIST === undefined || state.CUSTOMERSEARCH_DB_DELIVERY_LIST.item === undefined ? null : state.CUSTOMERSEARCH_DB_DELIVERY_LIST.item,
		config: state.CLIENT_CONFIG === undefined || state.CLIENT_CONFIG.item === undefined || state.CLIENT_CONFIG.item === null ? null : state.CLIENT_CONFIG.item.data,
		configAction: state.CLIENT_CONFIG === undefined ? null : state.CLIENT_CONFIG,
		itemAction: state.CUSTOMERSEARCH_DB_DELIVERY_LIST === undefined ? null : state.CUSTOMERSEARCH_DB_DELIVERY_LIST,
		/*
		ap: {
		  name: "CUSTOMERSEARCH_DB_DELIVERY_LIST",
		  reducers: deliveryReducers,
		  actionCreators: deliveryActionCreators
		},
		*/
	}
};

const mapDispatchToProps = (dispatch) => ({
	crudActions: bindActionCreators(deliveryActionCreators("CUSTOMERSEARCH_DB_DELIVERY_LIST"), dispatch),
	configCrudActions: bindActionCreators(configActionCreators("CLIENT_CONFIG"), dispatch),
	loadDataFromLocalObject: (hdsData) => {
		dispatch({
			type: 'CUSTOMERSEARCH_DB_DELIVERY_LIST_GET_SUCCESS',
			data: { data: hdsData }
		})
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPage);
