import _isArray from 'lodash-es/isArray';
import actionTypesFor from './actionTypesFor';
import createActionCreators from './action/create/actionCreator';
import deleteActionCreators from './action/delete/actionCreator';
import getActionCreators from './action/get/actionCreator';
import listActionCreators from './action/list/actionCreator';
import queryActionCreators from './action/query/actionCreator';
import patchActionCreators from './action/patch/actionCreator';
import updateActionCreators from './action/update/actionCreator';
import singleActionCreators from './action/single/actionCreator';
import fileActionCreators from './action/file/actionCreator';

export default function actionCreatorsFor(resourceName, types, api, options) {
	if (resourceName == null) throw new Error('actionCreatorsFor: Expected resourceName');
	if (!_isArray(types)) {
		types = ['LIST', 'GET', 'QUERY', 'CREATE', 'PATCH', 'UPDATE', 'DELETE', 'SINGLE', 'FILE']
	}

	options = options || {};

	let key = 'id';

	let actionTypes = actionTypesFor(resourceName, types);

	let actionCreators = {
		"name": resourceName
	};

	actionCreators["reset"] = function () {
		return function (dispatch) {
			dispatch({
				type: resourceName + "_RESET"
			})
		}
	};

	actionCreators["clear"] = function () {
		return function (dispatch) {
			dispatch({
				type: resourceName + "_CLEAR"
			})
		}
	};

	types.forEach(type => {
		switch (type) {
			case 'LIST':
				Object.assign(actionCreators, listActionCreators(resourceName, key, actionTypes, api, options));
				break;
			case 'GET':
				Object.assign(actionCreators, getActionCreators(resourceName, key, actionTypes, api, options));
				break;
			case 'QUERY':
				Object.assign(actionCreators, queryActionCreators(resourceName, key, actionTypes, api, options));
				break;
			case 'CREATE':
				Object.assign(actionCreators, createActionCreators(resourceName, key, actionTypes, api, options));
				break;
			case 'PATCH':
				Object.assign(actionCreators, patchActionCreators(resourceName, key, actionTypes, api, options));
				break;
			case 'UPDATE':
				Object.assign(actionCreators, updateActionCreators(resourceName, key, actionTypes, api, options));
				break;
			case 'DELETE':
				Object.assign(actionCreators, deleteActionCreators(resourceName, key, actionTypes, api, options));
				break;
			case 'SINGLE':
				Object.assign(actionCreators, singleActionCreators(resourceName, key, actionTypes, api, options));
				break;
			case 'FILE':
				Object.assign(actionCreators, fileActionCreators(resourceName, key, actionTypes, api, options));
				break;
		}
	});

	return actionCreators;
}