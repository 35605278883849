export default function reducers(resourceName, options) {
	if (resourceName == null) throw new Error('reducersFor: Expected resourceName');

	return {
		[resourceName + '_SINGLE_REQUEST']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: true,
				item: null
			});
		},

		[resourceName + '_SINGLE_FAILURE']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: false,
				item: null,
				error: payload,
				hasRequested: true
			});
		},

		[resourceName + '_SINGLE_SUCCESS']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: false,
				hasRequested: true,
				item: payload.data
			});
		},

		[resourceName + '_SINGLE_COMPLETE']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: false,
				hasRequested: false
			});
		}
	}
}