import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectAsyncReducer } from 'io-redux/store';
import { reducers as deliveryReducers, actionCreators as deliveryActionCreators } from '../resource/delivery';
import { translate } from '../../../common/translations';
injectAsyncReducer('DELIVERY_CONTACT_ITEM', deliveryReducers("DELIVERY_CONTACT_ITEM", ["UPDATE"]));

class DeliveryDetailsContactNameForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fullName: props.delivery.fullName || '',
			contactNumber: props.delivery.contactNumber || '',
			editFullName: props.delivery.fullName || '',
			editContactNumber: props.delivery.contactNumber || '',
			isEditing: false,
		}
	}

	submitForm(e) {
		e.preventDefault();
		const options = {
			clientId: this.props.deliveryConfig.clientId,
			postcode: this.props.delivery.postcode,
			fullName: this.state.editFullName,
			contactNumber: this.state.editContactNumber,
		};
		this.props.crudActions.update(
			encodeURIComponent(this.props.delivery.clientDeliveryIdentifier),
			options,
			null,
		);
		this.setState({ errorMessage: '', successMessage: '', isSubmitting: true })
	}

	cancelForm() {
		this.setState({ isEditing: false, editFullName: this.state.fullName, editContactNumber: this.state.contactNumber });
	}

	toggleForm() {
		this.setState({ isEditing: !this.state.isEditing });
	}

	handleChange(event) {
		const newValue = {};
		newValue[event.target.id] = event.target.value;
		this.setState(newValue);
	}

	componentWillReceiveProps(newProps, newState) {
		if (newProps.itemAction.error !== null) {
			this.setState({ isSubmitting: false, errorMessage: translate(newProps, 'Unable to save contact details'), successMessage: '' });
			return;
		}
		if (newProps.itemAction.hasUpdated === true) {
			this.setState({
				isEditing: false,
				isSubmitting: false,
				errorMessage: '',
				successMessage: translate(newProps, 'Details Saved'),
				fullName: newProps.item.data.fullName,
				contactNumber: newProps.item.data.contactNumber,
				editFullName: newProps.item.data.fullName,
				editContactNumber: newProps.item.data.contactNumber,
			});
			return;
		}
	}

	render() {
		const props = this.props;
		const styles = props.deliveryConfig.clientStyles;

		return (
			<div style={{ width: "100%" }}>
				<div className={(this.state.isEditing === true && props.deliveryConfig.portalCustomerModifyData ? 'hidden' : '') + ' col-12 text-center'}>
					{this.state.successMessage ? <p>{this.state.successMessage}</p> : null}
					{props.deliveryConfig.portalCustomerModifyData ? <div className="form-group">
						<input style={styles.button} type="submit" onClick={this.toggleForm.bind(this)} className="trackButton btn-lg btn-primary" value={translate(props.lang, "UPDATE CONTACT DETAILS")} />
					</div> : null}
				</div>

				<div className={(this.state.isEditing === false || !props.deliveryConfig.portalCustomerModifyData ? 'hidden' : '') + ' col-12 text-center'}>
					<form onSubmit={this.handleSubmit}>
						<div className="form-group">
							<label htmlFor="editFullName">{translate(this.props.lang, 'Name')}</label>
							<input
								type="text"
								placeholder={this.state.editFullName}
								className="input-lg form-control col-6 col-centered"
								id="editFullName"
								value={this.state.editFullName}
								onChange={this.handleChange.bind(this)} />
						</div>

						<div className="form-group">
							<label htmlFor="editContactNumber">{translate(this.props.lang, 'Phone Number')}</label>
							<input
								type="text"
								placeholder={this.state.editContactNumber}
								className="input-lg form-control col-6 col-centered"
								id="editContactNumber"
								value={this.state.editContactNumber}
								onChange={this.handleChange.bind(this)} />
						</div>

						<div className="form-group">
							{this.state.errorMessage ? <p>{this.state.errorMessage}</p> : null}
							{this.state.isSubmitting ? <p>{translate(this.props.lang, 'Sending Data')}</p> : null}
							<input style={styles.button} disabled={((this.state.isSubmitting) || (this.state.editContactNumber == '' || this.state.editFullName == ''))} type="submit" onClick={this.submitForm.bind(this)} className="trackButton btn-lg btn-primary mr-3 client-btn" value={translate(this.props.lang, "SAVE")} />
							<input type="button" onClick={this.cancelForm.bind(this)} className="trackButton btn-lg btn-cancel client-btn" value={translate(this.props.lang, "Cancel")} />
						</div>
					</form>
				</div>
			</div>
		);
	}
}

DeliveryDetailsContactNameForm.contextTypes = {

};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		searchParams: state.searchParams,
		searchValue: state.searchValue,
		item: state.DELIVERY_CONTACT_ITEM === undefined || state.DELIVERY_CONTACT_ITEM.item === undefined ? null : state.DELIVERY_CONTACT_ITEM.item,
		itemAction: state.DELIVERY_CONTACT_ITEM === undefined ? null : state.DELIVERY_CONTACT_ITEM,
	}
};

const mapDispatchToProps = (dispatch) => ({
	crudActions: bindActionCreators(deliveryActionCreators("DELIVERY_CONTACT_ITEM"), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailsContactNameForm);
