import React, { Component } from "react";
import format from "../../../common/dateFormat";
import { addMinutes, differenceInMinutes, parse, setMinutes, subHours, subMinutes } from "date-fns";
import DeliveryDetailsMap from "./DeliveryDetailsMap";
import DeliveryDetailsContactNameForm from "./DeliveryDetailsContactNameForm";
import DeliveryDetailsDeliveryNotesForm from "./DeliveryDetailsDeliveryNotesForm";
import { translate } from "../../../common/translations";
import DeliveryDetailsTimelineItem from "./DeliveryDetailsTimelineItem";
import DeliveryDetailsAddress from "./DeliveryDetailsAddress";
import {filterScanLog, groupScanLogByStatus} from 'common/filterScanLog';

String.prototype.replaceAt = function (index, replacement) {
  return (
    this.substr(0, index) +
    replacement +
    this.substr(index + replacement.length)
  );
};

class DeliveryDetailsPending extends Component {
  render() {
    const props = this.props;
    const delivery = this.props.delivery;
    const deliveryConfig = this.props.deliveryConfig;
    const refresh = this.props.refresh;
    const { boxes, scanLog } = delivery;

    const scanLogFiltered = filterScanLog(scanLog, boxes);
    const scanLogGrouped = groupScanLogByStatus(scanLogFiltered);

    if (delivery.deliveryNotes) {
      delivery.deliveryNotes =
        delivery.deliveryNotes.trim() === "" ? "" : delivery.deliveryNotes;
    }

    // 2021-02-14 09:36:51.000
    // 2021-03-03T13:07:04.875+11:00
    // 2020-10-24 01:17:23
    // 10:41:35
    // NULL

    let deliveryEta = delivery.etaDt;
    let etaTime = "";
    let deliveryDate = delivery.deliveryDateDt
      ? delivery.deliveryDateDt.substring(0, 10)
      : null;
    console.debug("Delivery Status: Pending");
    // console.log( "Delivery ETA", delivery.etaTime, deliveryDate );

    if (deliveryEta) {
      if (deliveryEta.substring(0, 3) === "202") {
        if (
          deliveryEta.indexOf("T") === -1 &&
          deliveryEta.indexOf(" ") === 10
        ) {
          deliveryEta = deliveryEta.replaceAt(10, "T");
        }

        etaTime = format(new Date(deliveryEta), this.props.lang, "HH:mm");
      } else {
        etaTime = format(
          new Date(deliveryDate + "T" + deliveryEta),
          this.props.lang,
          "HH:mm"
        );
      }
    }

    let today = new Date();
    let now = new Date(today.getTime());
    now = format(now, this.props.lang, "HH:mm");

    const minsToETA = differenceInMinutes(new Date(deliveryEta), new Date());

    // when ETA is missed
    const isArrivingSoon = minsToETA <= -5 && minsToETA >= -29;
    const isDelayed = minsToETA <= -30;

    let deliveryDateWithETA = new Date(deliveryDate + "T" + etaTime);

    console.log("ETA Time", deliveryDate, deliveryEta, etaTime);
    console.log("Delivery Date", deliveryDateWithETA, new Date());

    let deliveryTimePanel = "window";
    let showMovingWindow = false;

    if (
      deliveryConfig.showDeliveryETA &&
      etaTime &&
      minsToETA <= deliveryConfig.portalDeliveryEtaMaxMinutes &&
      delivery.hasRoundStarted
    ) {
      deliveryTimePanel = "eta";
    } else if (deliveryConfig.showDeliveryETA && delivery.hasRoundStarted) {
      // This stays as window
      showMovingWindow = true;
    }

    let startWindow = null;
    let endWindow = null;

    function tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1);
        time.pop(); // remove seconds
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join("");
    }

    if (deliveryConfig.showDeliveryWindow) {
      if (
        delivery.deliveryWindowStart === "00:00:00" &&
        delivery.deliveryWindowFinish === "00:00:00"
      ) {
        startWindow = "Calculating...";
        endWindow = null;
      } else {
        startWindow = parse(deliveryDate + " " + delivery.deliveryWindowStart);
        endWindow = parse(deliveryDate + " " + delivery.deliveryWindowFinish);
        if (startWindow === "Invalid Date" || endWindow === "Invalid Date") {
          startWindow = "Calculating...";
          endWindow = null;
        }
      }

      if (showMovingWindow && etaTime) {
        startWindow = subMinutes(deliveryDateWithETA, 60);
        endWindow = subMinutes(deliveryDateWithETA, -180);
      }

      // if run has started, render a 4hr time window
      if (delivery.hasRoundStarted && delivery.etaDt) {
        let etaDt = parse(delivery?.etaDt);
        etaDt = setMinutes(etaDt, 0); // reset mintues to 00
        // set eta to -1hr & +3 hours
        let earlyEta = subHours(etaDt, +1);
        let lateEta = subHours(etaDt, -3);
        startWindow = tConvert(
          format(new Date(earlyEta), this.props.lang, "HH:mm")
        );
        // if eta is after 6pm render 6pm.
        if (lateEta.getHours() > 18) {
          endWindow = "6:00pm";
        } else {
          lateEta = subHours(etaDt, -3);
          endWindow = tConvert(
            format(new Date(lateEta), this.props.lang, "HH:mm")
          );
        }
      } else {
        // if run has not started, render an all day time window
        startWindow = tConvert(delivery?.deliveryWindowStart);
        endWindow = tConvert(delivery?.deliveryWindowFinish);
      }
    }

    const styles = deliveryConfig.clientStyles;

    if (delivery.isCancelled) {
      deliveryTimePanel = "cancelled";
    } else if (delivery.deliveryStatus == "Not Delivered") {
      deliveryTimePanel = "not-delivered";
    }

    const friendlyFailedReason = (failedReason) => {
      return failedReason?.replace(/-/g, ' ');
    }

    return (
      <div className="deliveryDetails row">
        <div className="col-12">
          {deliveryTimePanel === "cancelled" && (
            <div className="row division white-bg">
              <div className="col-12 text-center">
                <h3 style={{ color: "#002bbb" }}>
                  {translate(
                    this.props.lang,
                    "Your Delivery Has Been Cancelled"
                  )}
                </h3>
              </div>
            </div>
          )}

          {deliveryTimePanel === "not-delivered" && (
            <div className="row division white-bg">
              <div className="col-12 text-center">
                <h3 style={{ color: "#002bbb" }}>
                  {translate(
                    this.props.lang,
                    "Your delivery was unable to be delivered"
                  )}
                </h3>
                <h4 style={{
                  fontSize: '13px',
                  color: 'red',
                  textTransform: 'capitalize'
                }}>{friendlyFailedReason(delivery.failedReason)}</h4>
              </div>
            </div>
          )}

          {deliveryTimePanel == "eta" ? (
            <div className="row division white-bg">
              <div className="col-12 text-center">
                {delivery.numberBefore > 0 &&
                delivery.numberBefore <=
                  deliveryConfig.portalDeliveryDistanceMaxStops &&
                deliveryConfig.showDeliveryDistance ? (
                  <h3 className="mb-2">
                    You are{" "}
                    <span style={styles.textHighlight}>
                      {delivery.numberBefore}
                    </span>{" "}
                    deliver{delivery.numberBefore > 1 ? 'ies' : 'y'} away
                  </h3>
                ) : null}
                <h3>
                  {translate(this.props.lang, "Estimated delivery time is")}
                </h3>
                <p style={styles.textHighlight} className="deliveryTime">
                  {!isArrivingSoon && !isDelayed && format(deliveryDateWithETA, this.props.lang, "h:mma")}
                  {isArrivingSoon && translate(this.props.lang, 'Arriving soon...') }
                  {isDelayed && translate(this.props.lang, 'Delayed') }
                </p>
                <p className="deliveryEtaOuttro">
                  {translate(
                    this.props.lang,
                    "Estimate is updated every 5 minutes"
                  )}
                </p>
              </div>
            </div>
          ) : null}

          {deliveryTimePanel == "window" ? (
            <div className="row division white-bg">
              <div className="col-12 text-center">
                {delivery.numberBefore > 0 &&
                delivery.numberBefore <=
                  deliveryConfig.portalDeliveryDistanceMaxStops &&
                deliveryConfig.showDeliveryDistance ? (
                  <h3 className="mb-2">
                    You are{" "}
                    <span style={styles.textHighlight}>
                      {delivery.numberBefore}
                    </span>{" "}
                    deliver{delivery.numberBefore > 1 ? 'ies' : 'y'} away
                  </h3>
                ) : null}
                <h3>
                  {translate(
                    this.props.lang,
                    "Your Delivery Is Estimated To Arrive"
                  )}
                </h3>
                {startWindow && endWindow ? (
                  <p style={styles.textHighlight} className="deliveryTime">
                    {startWindow} - {endWindow}
                  </p>
                ) : null}
                <p className="mb-0" style={styles.textHighlight}>
                  {format(
                    deliveryDate,
                    this.props.lang,
                    "dddd Do MMMM YYYY"
                  ).toString()}
                </p>
              </div>
            </div>
          ) : null}

          {deliveryConfig.showDeliveryDetails && (
            <>
              <DeliveryDetailsMap
                lang={props.lang}
                latitude={delivery.latitude}
                longitude={delivery.longitude}
                deliveryConfig={deliveryConfig}
              />

              {boxes && (
                <DeliveryDetailsAddress
                  lang={props.lang}
                  delivery={delivery}
                  styles={styles}
                  boxes={boxes}
                />
              )}
            </>
          )}

          {scanLogFiltered && scanLogFiltered.length > 0 && (
            <div className="row pt-2 px-4 division white-bg timeline-container rounded-b-lg">
              {
                Object.keys(scanLogGrouped).map((key) => (
                  <DeliveryDetailsTimelineItem lang={props.lang}
                    key={[...scanLogGrouped[key]].pop().scannedDt}
                    styles={styles}
                    data={[...scanLogGrouped[key]].pop()}
                    deliveryStatus={delivery.deliveryStatus}
                    boxScans={scanLogGrouped[key]}
                  />
                ))
              }
            </div>
          )}

          {!delivery.isCancelled && (
            <div className="row pt-0 division">
              <DeliveryDetailsContactNameForm
                lang={props.lang}
                delivery={delivery}
                deliveryConfig={deliveryConfig}
              />
            </div>
          )}

          {!delivery.isCancelled && deliveryConfig.showDeliveryNotes && (
            <div className="row division white-bg rounded-lg">
              <DeliveryDetailsDeliveryNotesForm
                lang={props.lang}
                delivery={delivery}
                deliveryConfig={deliveryConfig}
              />
            </div>
          )}

          <p className="row">&nbsp;</p>
        </div>
      </div>
    );
  }
}

export default DeliveryDetailsPending;
