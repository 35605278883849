import { createReducer } from 'io-redux/createReducer';
import {
    SEARCH_PARAMS_REQUEST, SEARCH_PARAMS_SUCCESS, SEARCH_PARAMS_FAILURE
} from '../constants/SearchParams';

const initialState = {

};

export default createReducer( initialState, {
    [ SEARCH_PARAMS_SUCCESS ]: ( state, data ) => {
        return Object.assign( {}, state, data.payload );
    }
} );