import React, { Component } from 'react';
import { connect } from 'react-redux';
import HereMapWithMarker from './HereMapWithMarker';
class DeliveryDetailsMap extends Component {

	constructor(props) {
		super(props);

		this.state = { windowWidth: 0, windowHeight: 0, mapHeight: 200 };
		this.handleResize = this.handleResize.bind(this);
	}

	handleResize() {
		this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	}

	componentDidUpdate() {
		let mapHeight = 200;

		if(this.state.windowWidth > 768) {
			mapHeight = 500;
		}

		if(this.state.mapHeight !== mapHeight) {
			this.setState({ mapHeight });
		}
	}

	render() {
		const props = this.props;
		const state = this.state;

		return (
			<div className="row division pt-0 mt-0">
				<div style={{ width: "100%" }}>
					<div className="here-maps" style={{ height: `${state.mapHeight}px`, width: `100%` }}>
						<HereMapWithMarker latitude={props.latitude} longitude={props.longitude} deliveryConfig={props.deliveryConfig} height={state.mapHeight} />
					</div>
				</div>
			</div>
		)
	}
}

DeliveryDetailsMap.contextTypes = {

};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailsMap);
