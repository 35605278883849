export default function queryActionCreators(resourceName, key, actionTypes, api, options) {
	if (resourceName == null) throw new Error('actionCreatorsFor: Expected resourceName');

	let actionCreators = {

		query: function (token, params) {
			return function (dispatch) {
				dispatch(actionCreators.request());

				return api.query(token, params)
					.then(
						response => dispatch(actionCreators.success(response)),
						error => dispatch(actionCreators.failure(error))
					).then(
						response => dispatch(actionCreators.complete()),
						error => dispatch(actionCreators.complete())
					);
			}
		},

		request: function () {
			let name = resourceName + '_QUERY_REQUEST';

			return {
				type: actionTypes[name]
			}
		},

		success: function (data) {
			let name = resourceName + '_QUERY_SUCCESS';

			return {
				data: data.data,
				payload: data,
				type: actionTypes[name]
			}
		},

		complete: function () {
			let name = resourceName + '_QUERY_COMPLETE';

			return {
				data: {},
				type: actionTypes[name]
			}
		},

		failure: function (error, data) {
			let name = resourceName + '_QUERY_FAILURE';

			return {
				data: data,
				error: error,
				type: actionTypes[name]
			}
		}
	};

	return actionCreators;
}