import _uniqBy from 'lodash-es/uniqBy';
import _isString from 'lodash-es/isString';

export default function reducers(resourceName, options) {
	if (resourceName == null) throw new Error('reducersFor: Expected resourceName');

	return {
		[resourceName + '_LIST_CLEAR']: (state, payload) => {
			return Object.assign({}, state, {
				isRequesting: false,
				isCleared: true,
				items: [],
				meta: {}
			});
		},

		[resourceName + '_LIST_REQUEST']: (state, payload) => {
			return Object.assign({}, state, {
				isRequesting: true,
				hasRequested: false
			});
		},

		[resourceName + '_LIST_FAILURE']: (state, payload) => {
			return Object.assign({}, state, {
				isRequesting: false,
				items: [],
				meta: {},
				error: payload,
				hasRequested: true
			});
		},

		[resourceName + '_LIST_SUCCESS']: (state, payload) => {

			let items = [];
			if (payload.shouldMerge === true) {
				items = state.items === undefined ? payload.data : state.items.concat(payload.data);
			} else if (_isString(payload.shouldMerge)) {
				items = state.items === undefined ? payload.data : state.items.concat(payload.data);
				items = _uniqBy(items, payload.shouldMerge);
			} else {
				items = payload.data;
			}

			return Object.assign({}, state, {
				isRequesting: false,
				items: items,
				meta: payload.meta,
				hasRequested: true
			});
		},

		[resourceName + '_LIST_COMPLETE']: (state, payload) => {
			return Object.assign({}, state, {
				isRequesting: false,
				hasRequested: false
			});
		}
	}
}