import { translate } from '../../../common/translations';
import { useState } from 'react';

const DeliveryDetailsTimelineItem = ({ data, delivered, styles, refresh, lang, boxScans }) => {

  const { displayMessage, scannedDt, status, toDepot, deliveryDate, deliveryTime } = data;

  const [ isExpanded, setIsExpanded ] = useState(false);

  const formatDisplayDate = (time) => {
    const dd = new Date(Date.parse(time))
    return  dd.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  }

  const formatDisplayTime = (time) => {
    const dd = new Date(Date.parse(time))
    return dd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  }

  const formatDeliveryDate = (date) => {
    const da = new Date(Date.parse(deliveryDate))
    return da.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  }

  // Format displayDate
  const displayDate = formatDisplayDate(scannedDt)
  const formattedDeliveryDate = formatDeliveryDate(deliveryDate);

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  const displayTime = formatDisplayTime(scannedDt);
  const formattedDeliveryTime = deliveryTime ? convertTime12to24(deliveryTime) : null;

  const processDisplayMessage = (displayMessage, lang) => {
    switch(displayMessage){
      case 'in-progress': return translate(lang, 'Out for Delivery');
      case 'van': return translate(lang, 'Sorted & ready for dispatch at warehouse');
      case 'returnToDepot': return translate(lang, 'Re-sort');
      default: return displayMessage;
    }
  }

  const processScanStatus = (status, lang) => {
    switch(status){
      case 'pickup': return translate(lang, 'Ready for Pickup from Supplier');
      case 'incoming': return translate(lang, 'Received to refrigerated warehouse');
      case 'van': return translate(lang, 'Ready for Dispatch');
      case 'scannedOn': return translate(lang, 'Driver - On Vehicle for Delivery');
      case 'scannedOff': return translate(lang, 'Driver - Scanned at Delivery Location');
      default: return status;
    }
  }

  let depot = null;

  if (displayMessage?.includes('Parcel received at ')) {
    depot = displayMessage.split('Parcel received at ')[1];
  }

  return (
    <>
      <div className="timeline-item">
        <div className="timeline-content">

          {/* Disabled until we finalize delivery status */}
          {/* Render dashed line below icon  */}
          {/* { status === 'delivered' && */}
          {/* <div className="before-block"></div> */}
          {/* } */}

          {/* Render solid line below icon if not just picked up or dropped off */}
          {/* { status !== 'delivered' && status !== 'incoming' && status !== 'pickup' && */}
          {/* <div className="after-block"></div> */}
          {/* } */}


          {delivered && status === 'delivered' &&
            <div className="timeline-icon delivered">
              <i className="fas fa-home"></i>
            </div>
          }

          {!delivered && status === 'van' &&
            <div className="timeline-icon van">
              <i className="fa-solid fa-hand-holding-box"></i>
            </div>
          }

          {status !== 'van' && status !== 'delivered' &&
            <div className="timeline-icon ready">
              <i className="fa-solid fa-box"></i>
            </div>
          }

          <div className="timeline-content-text">
            <h3 className="subheading" style={styles.textHighlight}>
              <div>
                {processScanStatus(status, lang) === status ? processDisplayMessage(displayMessage, lang) :
                  <>{processScanStatus(status, lang)} {depot} {toDepot && <span>{` ${toDepot.stateAbbr}`}</span>}</>
                }
              </div>
              {boxScans && (
                <button className="box-count-toggle" onClick={() => {setIsExpanded(!isExpanded)}}>
                  {boxScans.length} 
                  <i className="fa fa-solid fa-boxes"></i> 
                  {isExpanded ? <i className="fa fa-solid fa-angle-up"></i> : <i className="fa fa-solid fa-angle-down"></i>}
                </button>
              )}
            </h3>
            <div className="timeline-content-date">
              <p>
                {displayDate && displayDate !== 'Invalid Date' && displayDate}
                {formattedDeliveryDate && displayDate === 'Invalid Date' && formattedDeliveryDate}
              </p>
              <p className="text-lowercase">
                {displayTime && displayTime !== 'Invalid Date' && displayTime}
                {deliveryTime && displayTime === 'Invalid Date' && formattedDeliveryTime}
              </p>
            </div>
            {isExpanded && (
              <div className="timeline-content-boxes">
              {boxScans && boxScans.map((box) => {
                
                const displayDate = formatDisplayDate(box.scannedDt)
                const formattedDeliveryDate = formatDeliveryDate(box.deliveryDate);
                const displayTime = formatDisplayTime(box.scannedDt);
                const formattedDeliveryTime = deliveryTime ? convertTime12to24(box.deliveryTime) : null;

                return (
                  <div className="timeline-content-box" key={box.barcode}>
                    <div className="timeline-content-box-barcode">
                      {box.barcode}
                    </div>
                    <div className="timeline-content-box-datetime">
                      <p>
                        {displayDate && displayDate !== 'Invalid Date' && displayDate}
                        {formattedDeliveryDate && displayDate === 'Invalid Date' && formattedDeliveryDate}
                      </p>
                      <p className="ml-2">
                        {displayTime && displayTime !== 'Invalid Date' && displayTime}
                        {deliveryTime && displayTime === 'Invalid Date' && formattedDeliveryTime}
                      </p>
                    </div>
                  </div>
                )
                }
              )}
              </div>
            )}
          </div>
        </div>
        {
          status === 'pending' &&
          <div style={{ marginTop: '-1rem' }}>
            {refresh}
          </div>
        }
      </div>
    </>
  );
}

export default DeliveryDetailsTimelineItem;