export default function reducers(resourceName, args) {
	if (resourceName == null) throw new Error('reducersFor: Expected resourceName');

	return {
		[resourceName + '_UPDATE_REQUEST']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingUpdate: true,
				hasUpdated: false,
				error: null,
			});
		},

		[resourceName + '_UPDATE_FAILURE']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingUpdate: false,
				item: null,
				hasUpdated: false,
				error: payload
			});
		},

		[resourceName + '_UPDATE_SUCCESS']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingUpdate: false,
				item: payload.data,
				hasUpdated: true,
				error: null,
			});
		},

		[resourceName + '_UPDATE_COMPLETE']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingUpdate: false,
				hasUpdated: false
			});
		}
	}
}