import { Switch, Route } from 'react-router-dom';
import React, { Component } from 'react';
import DeliveryPage from './page/DeliverySearch';

export default (props) => {
	return (
		<div>
			<Switch>
				<Route path={`${props.match.path}/client/:reference(.+)`} component={DeliveryPage} />
				<Route path={`${props.match.path}/:postcode/:reference/:trackingHash`} component={DeliveryPage} />
				<Route path={`${props.match.path}/:postcode/:reference`} component={DeliveryPage} />
				<Route path={`${props.match.path}/:trackingHash`} component={DeliveryPage} />
				<Route component={DeliveryPage} />
			</Switch>
		</div>
	)
}