import { useState } from 'react';
import { translate } from '../../../common/translations';


const DeliveryDetailsAddress = ({ lang, boxes, delivery, styles }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className="row division pt-0 pb-0 mt-0 white-bg">
			<div className="col-12 address">
				<div>
					<h3 className="subheading" style={styles.textHighlight}>{translate(lang, 'Address')}</h3>
					{delivery.city} {delivery.state} {delivery.postcode}<br />
				</div>

				<div>
					<h3 className="subheading" style={styles.textHighlight}>{translate(lang, 'Carrier')}</h3>
					<div>{delivery.service}</div>
				</div>
			</div>

			<div className="col-12 mb-2">
				<h3 className="subheading" style={styles.textHighlight}>
					{translate(lang, 'Boxes')}
					<button className="box-count-toggle" onClick={() => {setIsExpanded(!isExpanded)}}>
						{boxes.length} 
						<i className="fa fa-solid fa-boxes"></i> 
						{isExpanded ? <i className="fa fa-solid fa-angle-up"></i> : <i className="fa fa-solid fa-angle-down"></i>}
					</button>
				</h3>

				{isExpanded && (
					<div className="boxes-list">
						{boxes && boxes !== undefined && boxes.map((box, index) =>
							<p key={index}>(# {index + 1}) {' '} {box.barcode}</p>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default DeliveryDetailsAddress;