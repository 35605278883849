export default function getActionCreators(resourceName, key, actionTypes, api, options) {
	if (resourceName == null) throw new Error('actionCreatorsFor: Expected resourceName');

	let actionCreators = {

		getRefresh: function (id, token, params) {
			return function (dispatch) {
				dispatch(actionCreators.requestRefresh());

				return api.get(id, token, params)
					.then(
						response => dispatch(actionCreators.success(response)),
						error => dispatch(actionCreators.failure(error))
					).then(
						response => dispatch(actionCreators.complete()),
						error => dispatch(actionCreators.complete())
					);
			}
		},

		get: function (id, token, params, refresh = false) {
			return function (dispatch) {
				dispatch(refresh ? actionCreators.requestRefresh() : actionCreators.request());

				return api.get(id, token, params)
					.then(
						response => dispatch(actionCreators.success(response)),
						error => dispatch(actionCreators.failure(error))
					).then(
						response => dispatch(actionCreators.complete()),
						error => dispatch(actionCreators.complete())
					);
			}
		},

		requestRefresh: function () {
			let name = resourceName + '_GET_REQUEST_REFRESH';

			return {
				type: actionTypes[name]
			}
		},

		request: function () {
			let name = resourceName + '_GET_REQUEST';

			return {
				type: actionTypes[name]
			}
		},

		success: function (data) {
			let name = resourceName + '_GET_SUCCESS';

			return {
				data: options.hasIndividualDataWrapper ? data.data : data,
				type: actionTypes[name]
			}
		},

		complete: function () {
			let name = resourceName + '_GET_COMPLETE';

			return {
				data: {},
				type: actionTypes[name]
			}
		},

		failure: function (error, data) {
			let name = resourceName + '_GET_FAILURE';

			return {
				data: data,
				error: error,
				type: actionTypes[name]
			}
		}
	};

	return actionCreators;
}