export default function reducers(resourceName, args) {
	if (resourceName == null) throw new Error('reducersFor: Expected resourceName');

	return {
		[resourceName + '_GET_REQUEST_REFRESH']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: true,
				error: null,
				hasRequested: false
			});
		},

		[resourceName + '_GET_REQUEST']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: true,
				error: null,
				item: null,
				hasRequested: false
			});
		},

		[resourceName + '_GET_FAILURE']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: false,
				item: null,
				error: payload,
				hasRequested: true
			});
		},

		[resourceName + '_GET_SUCCESS']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: false,
				item: payload.data,
				error: null,
				hasRequested: true
			});
		},

		[resourceName + '_GET_COMPLETE']: (state, payload) => {
			return Object.assign({}, state, {
				isRequestingItem: false,
				hasRequested: false
			});
		}
	}
}