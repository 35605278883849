const formatScanLogId = (barcode, scanStatus) => `${barcode}<<>>${scanStatus}`;

// Filter scanLog to remove duplicate status pick ups eg 2x 'pick up' scans for same boxId
const filterScanLog = (scanLog, boxes) => Array.from(new Set(scanLog?.map(a => formatScanLogId(a.barcode, a.status))))
    .map((scanLogId) => {
        return {...scanLog.find(a => formatScanLogId(a.barcode, a.status) === scanLogId)}
    })
    .filter((scanLog) => scanLog.status !== 'missing');

const groupScanLogByStatus = (scanLog) => scanLog.reduce((scanlog, curr) => {
    if (!scanlog[curr.status]) {
        scanlog[curr.status] = [];
    }
    scanlog[curr.status].push(curr);
    return scanlog;
}, {})

// Legacy Filter scanLog to remove duplicate status pick ups eg 2x 'pick up' scans 
// This is replicated across different delivery states so move it to a custom hook when you get a chance
// const scanLogFiltered = Array.from(new Set(scanLog?.map(a => a.status)))
// 	.map(status => {
// 		return scanLog.find(a => a.status === status)
// 	})

export {groupScanLogByStatus, filterScanLog};