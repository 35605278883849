import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectAsyncReducer } from 'io-redux/store';
import { Modal, Button } from 'react-bootstrap';
import { reducers as deliveryReducers, actionCreators as deliveryActionCreators } from '../resource/deliveryfeedbackrating';
import { translate } from '../../../common/translations';
injectAsyncReducer('DELIVERY_FEEDBACK_RATING', deliveryReducers("DELIVERY_FEEDBACK_RATING", ["UPDATE"]));

class DeliveryDetailsThumbs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      driverRate: props.delivery.customerRating || 0,
      hoverDriverRate: props.delivery.customerRating || '',
      notes: props.delivery.ratingNotes || '',
      ratingIssue: props.delivery.ratingIssue || '',
      editable: !(props.delivery.customerRating > 0),
      feedbackRemarks: props.delivery.feedbackRemarks || '',
      isSubmitting: false,
      errorMessage: '',
      successMessage: '',
      addAComment: false,
      comment: props.delivery.feedbackRemarks || '',
      receivedDelivery: null,
      showModal: false
    }

  }

  handleShowModal = () => {
    this.setState({
      showModal: true,
      driverRate: 5
    })
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false
    })
  }

  submitRating (e) {
    e.preventDefault();

    if (!this.state.isSubmitting && this.state.editable) {
      this.setState({
        isSubmitting: true
      });

      let options = {
        clientId: this.props.deliveryConfig.clientId,
        postcode: this.props.delivery.postcode,
        rating: {
          score: this.state.driverRate
        },
        feedback: this.state.feedbackRemarks,
        url: window.location.href,
        receivedDelivery: this.state.receivedDelivery
      };

      this.props.crudActions.update(
        encodeURIComponent(this.props.delivery.clientDeliveryIdentifier),
        options,
        null,
      );

      this.setState({
        errorMessage: '',
        successMessage: '',
        isSubmitting: true
      })
    }

  }

  setRating (rating) {
    this.setState({
      driverRate: rating
    });
  }

  toggleAddAComment () {
    this.setState({
      addAComment: !this.state.addAComment
    })
  }

  setFeedback () {
    this.setState({
      feedbackRemarks: this.state.comment,
      addAComment: false
    })
  };

  componentWillReceiveProps (newProps, newState) {
    if (newProps.itemAction.error !== null) {
      this.setState({ editable: true, isSubmitting: false, errorMessage: translate(newProps, 'Unable to save rating'), successMessage: '' });
      return;
    }
    if (newProps.itemAction.hasUpdated === true) {
      this.setState({ editable: false, isSubmitting: false, errorMessage: '', successMessage: translate(newProps, 'Thank you for your feedback.') });
      return;
    }
  }

  render () {
    const props = this.props;
    const styles = props.deliveryConfig.clientStyles;

    return (
      <div key="ratingForm" className="row division white-bg rounded-lg">
        <div className="col-12 text-center">
          <h3 className="mb-3">How Was Your Experience?</h3>
          <div style={ { margin: '0 auto', maxWidth: '400px' } }>
            { (!this.isSubmitting && (this.state.successMessage !== '' || this.state.errorMessage !== '')) ? (
              <div>
                <div className="mb-3">
                  <img src={ `/img/thumbs-${ this.state.driverRate == 1 ? 'down' : 'up' }-blue.png` } alt="Rating" />
                </div>
                { this.state.successMessage ? <div>{ this.state.successMessage }</div> : null }
                { this.state.errorMessage ? <div>{ this.state.errorMessage }</div> : null }
              </div>
            ) : (
              <div>
                <div className="thumbs row">
                  <div className="col-6 text-right">
                    <a onClick={ this.setRating.bind(this, 1) } >
                      <img src={ `/img/thumbs-down-${ this.state.driverRate == 1 ? 'blue' : 'grey' }.png` } alt="Thumbs Down" />
                    </a>
                  </div>
                  <div className="col-6 text-left">
                    <a
                      p
                      onClick={ this.handleShowModal }
                    >
                      <img src={ `/img/thumbs-up-${ this.state.driverRate == 5 ? 'blue' : 'grey' }.png` } alt="Thumbs Up" />
                    </a>
                  </div>
                </div>

                {/* Thumbs DOWN selected */ }
                { this.state.driverRate === 1 && (
                  <div>
                    <div className="select form-group mt-3">
                      <select className="form-control"
                        value={ this.state.feedbackRemarks }
                        onChange={ (e) => this.setState({ feedbackRemarks: e.target.value }) }
                      >
                        <option value="">{ translate(props.lang, 'Select One...') }</option>
                        <option value="There was a problem with the product">{ translate(props.lang, 'There was a problem with the product') }</option>
                        <option value="Missing Box">{ translate(props.lang, 'Missing Box(es)') }</option>
                        <option value="Too early">{ translate(props.lang, 'Too early') }</option>
                        <option value="Too late">{ translate(props.lang, 'Too late') }</option>
                        <option value="Instructions not followed">{ translate(props.lang, 'Instructions not followed') }</option>
                        <option value="Wrong box">{ translate(props.lang, 'Wrong box') }</option>
                        <option value="Delivered to the wrong address">{ translate(props.lang, 'Delivered to the wrong address') }</option>
                        <option value="Empty Styrofoam esky not collected">{ translate(props.lang, 'Empty Styrofoam esky not collected') }</option>
                      </select>
                    </div>
                    { (this.state.feedbackRemarks !== 'There was a problem with the product' && this.state.feedbackRemarks !== '') && (
                      <div className="select form-group mt-3">
                        { translate(props.lang, 'Can you confirm if you are in possession of your delivery?') }
                        <div className="mt-3">
                          <button
                            type="button"
                            style={ styles.button }
                            className={ "btn btn-primary trackButton  text-uppercase client-btn grey " + ((this.state.receivedDelivery) ? ' active' : '') }
                            onClick={ () => {
                              this.setState({ receivedDelivery: true })
                            } }
                          >{ translate(props.lang, 'YES') }</button> &nbsp;&nbsp;
                          <button
                            type="button"
                            style={ styles.button }
                            className={ "btn btn-primary trackButton  text-uppercase client-btn grey" + ((this.state.receivedDelivery === false) ? ' active' : '') }
                            onClick={ () => {
                              this.setState({ receivedDelivery: false })
                            } }
                          >{ translate(props.lang, 'NO') }</button>
                        </div>
                      </div>
                    ) }
                  </div>
                ) }

                {/* Thumbs UP selected */ }
                { this.state.driverRate === 5 && (
                  <div className="notes mt-3">
                    { !this.state.addAComment && (
                      <div>
                        <div className="text-left small mb-4">
                          { this.state.feedbackRemarks }
                        </div>

                        <button
                          className="btn-cancel semi-block-width text-uppercase"
                          onClick={ this.toggleAddAComment.bind(this) }
                        >
                          { this.state.feedbackRemarks.trim() == '' ? translate(props.lang, 'Add Comment') : translate(props.lang, 'Edit Comment') }
                        </button>
                      </div>
                    ) }

                    { this.state.addAComment && (
                      <div>
                        <textarea
                          placeholder={ translate(props.lang, 'Leave a comment') }
                          rows="5"
                          className="form-control"
                          value={ this.state.comment }
                          onChange={ (e) => this.setState({ ...this.state, comment: e.currentTarget.value }) }
                        />

                        <button
                          className="mt-2 btn-cancel semi-block-width text-uppercase"
                          onClick={ this.toggleAddAComment.bind(this) }
                        >
                          { translate(props.lang, 'Cancel') }
                        </button>

                        <button
                          style={ styles.button }
                          className="trackButton semi-block-width mt-2 text-uppercase client-btn"
                          onClick={ this.setFeedback.bind(this) }
                        >
                          { translate(props.lang, 'Add Comment') }
                        </button>
                      </div>
                    ) }
                  </div>
                ) }

                { !this.state.addAComment && (
                  <button
                    type="button"
                    style={ styles.button }
                    className="btn btn-primary trackButton semi-block-width mt-3 text-uppercase client-btn"
                    onClick={ this.submitRating.bind(this) }
                    disabled={ (this.state.driverRate !== 1 && this.state.driverRate !== 5) || this.state.isSubmitting }>{ translate(props.lang, 'Submit') }</button>
                ) }
              </div>
            ) }
          </div>
        </div>

        <Modal show={ this.state.showModal } onHide={ this.handleCloseModal }>
          <Modal.Header>
            <Modal.Title>Thank you!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p></p>
            <p>Please consider leaving us a review on Google.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={ this.handleCloseModal }>
              Close
            </Button>
            <a href="https://g.page/home-delivery-service-hawthorn/review?rc" target="_blank">
              <Button variant="primary" onClick={ this.handleCloseModal }>
                Leave a review
              </Button>
            </a>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

DeliveryDetailsThumbs.contextTypes = {

};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    searchParams: state.searchParams,
    searchValue: state.searchValue,
    item: state.DELIVERY_FEEDBACK_RATING === undefined || state.DELIVERY_FEEDBACK_RATING.item === undefined ? null : state.DELIVERY_FEEDBACK_RATING.item,
    itemAction: state.DELIVERY_FEEDBACK_RATING === undefined ? null : state.DELIVERY_FEEDBACK_RATING,
  }
};

const mapDispatchToProps = (dispatch) => ({
  crudActions: bindActionCreators(deliveryActionCreators("DELIVERY_FEEDBACK_RATING"), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailsThumbs);
