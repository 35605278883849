export default function listActionCreators(resourceName, key, actionTypes, api, options) {
	if (resourceName == null) throw new Error('actionCreatorsFor: Expected resourceName');

	let actionCreators = {

		list: function (token, params, shouldMerge) {
			return function (dispatch) {
				dispatch(actionCreators.request());

				return api.list(token, params)
					.then(
						response => dispatch(actionCreators.success(response, shouldMerge)),
						error => dispatch(actionCreators.failure(error))
					).then(
						response => dispatch(actionCreators.complete()),
						error => dispatch(actionCreators.complete())
					);
			}
		},

		clear: function () {
			let name = resourceName + '_LIST_CLEAR';

			return {
				type: actionTypes[name]
			}
		},

		request: function () {
			let name = resourceName + '_LIST_REQUEST';

			return {
				type: actionTypes[name]
			}
		},

		success: function (data, shouldMerge) {
			let name = resourceName + '_LIST_SUCCESS';

			if (options.hasMeta) {
				return {
					shouldMerge: shouldMerge,
					meta: {
						count: data.count,
						limit: data.limit,
						sort: data.sort,
						offset: data.offset,
						alphaPagination: data.alphaPagination
					},
					data: data.data,
					type: actionTypes[name]
				}
			} else {
				return {
					shouldMerge: shouldMerge,
					data: data.data,
					type: actionTypes[name]
				}
			}
		},

		complete: function () {
			let name = resourceName + '_LIST_COMPLETE';

			return {
				data: {},
				type: actionTypes[name]
			}
		},

		failure: function (error, data) {
			let name = resourceName + '_LIST_FAILURE';

			return {
				data: data,
				error: error,
				type: actionTypes[name]
			}
		}
	};

	return actionCreators;
}