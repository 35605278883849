import { format } from 'date-fns'
import config from '../app/config';

const locales = {
	en_AU: require('date-fns/locale/en'),
	en_US: require('date-fns/locale/en'),
	nl_NL: require('date-fns/locale/nl'),
	de_DE: require('date-fns/locale/de'),
};

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export default function (date, language, formatStr = 'PP') {
	return format(date, formatStr, {
		locale: locales[language] // or global.__localeId__
	})
}