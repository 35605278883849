import rest from 'io-redux/crud/rest';
import actionCreatorsFor from 'io-redux/crud/actionCreatorsFor';
import reducersFor from 'io-redux/crud/reducersFor';

const onReceive = (data) => {
	return data;
};

const onSend = (data) => {
	return data;
};

const path = (id, params, token) => {
	return "api/DeliveryFeedback/RatingFeedback";
};

export function actionCreators(storePath, types) {
	return actionCreatorsFor(storePath, types, rest(path, onReceive, onSend), {
		hasMeta: false,
	});
}

export function reducers(storePath, types) {
	return reducersFor(storePath, types);
}