export default function patchActionCreators(resourceName, key, actionTypes, api) {
	if (resourceName == null) throw new Error('actionCreatorsFor: Expected resourceName');

	let actionCreators = {

		patch: function (id, params, token) {
			return function (dispatch) {
				dispatch(actionCreators.request());

				return api.patch(id, params, token)
					.then(
						response => dispatch(actionCreators.success(response)),
						error => dispatch(actionCreators.failure(error))
					).then(
						response => dispatch(actionCreators.complete()),
						error => dispatch(actionCreators.complete())
					);
			}
		},

		request: function () {
			let name = resourceName + '_PATCH_REQUEST';

			return {
				type: actionTypes[name]
			}
		},

		success: function (data) {
			let name = resourceName + '_PATCH_SUCCESS';

			return {
				data: data,
				type: actionTypes[name]
			}
		},

		complete: function () {
			let name = resourceName + '_PATCH_COMPLETE';

			return {
				data: {},
				type: actionTypes[name]
			}
		},

		failure: function (error, data) {
			let name = resourceName + '_PATCH_FAILURE';

			return {
				data: data,
				error: error,
				type: actionTypes[name]
			}
		}
	};

	return actionCreators;
}