const DeliveryNotFound = () => {

  return (
    <div className="row text-center white-bg error">
      <div className="col-12">
        <div className="icon-container">
          <i className="fa-solid fa-circle-exclamation"/>
        </div>
        <i className="fa-solid fa-circle-exclamation"></i>
        <h1 className="error-h1">Error</h1>
        <p className="error-p">We're having trouble finding your delivery details.<br />
          <small><strong>Please check the delivery details and try again.</strong></small></p>
      </div>
    </div>
  );
}

export default DeliveryNotFound;