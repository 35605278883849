const DeliveryCancelled = () => {

  return (
    <div className="row text-center white-bg error">
      <div className="col-12">
        <div className="icon-container">
          <i className="fa-solid fa-circle-exclamation" />
        </div>
        <h1 className="error-h1">Cancelled</h1>
        <p className="error-p">This delivery has been cancelled.</p>
      </div>
    </div>
  );
}

export default DeliveryCancelled;