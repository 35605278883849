import React, { Component } from 'react';
import { translate } from '../../../common/translations';

class CustomerSearchForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			searchValue: props.searchValue || '',
			searchPostcode: props.searchPostcode || ''
		}
	}

	componentWillReceiveProps(nextProps, nextState) {
		this.setState({
			searchValue: nextProps.searchValue || '',
			searchPostcode: nextProps.searchPostcode || ''
		});
	}

	handleSubmit(e) {
		e.preventDefault();
		this.props.handleSubmit(this.state.searchValue, this.state.searchPostcode);
	}

	handleChange(e) {
		const newValue = {};
		newValue[e.target.id] = e.target.value;
		this.setState(Object.assign({}, this.state, newValue));
	}

	render() {
		const props = this.props;
		const styles = props.deliveryConfig.clientStyles;

		return (
			<div className="searchform row white-bg">
				<div className="col-12">
					<form onSubmit={this.handleSubmit.bind(this)}>
						<div className="form-group">
							<label htmlFor="searchValue">{translate(this.props.lang,'TRACKING CODE')}</label>
							<input
								type="text"
								placeholder="Tracking Code"
								className="input-lg form-control col-6 col-centered"
								id="searchValue"
								value={this.state.searchValue}
								onChange={this.handleChange.bind(this)} />
						</div>

						<div className="form-group mt-4">
							<label htmlFor="searchPostcode">{translate(this.props.lang,'POSTCODE')}</label>
							<input
								type="text"
								placeholder="Postcode"
								className="input-lg form-control col-6 col-centered"
								id="searchPostcode"
								value={this.state.searchPostcode}
								onChange={this.handleChange.bind(this)} />
						</div>

						<div className="form-group mt-4">
							<button disabled={props.loading} type="submit" style={styles.button} className="trackButton btn-lg btn-primary" >{props.loading ? "LOADING" : "TRACK MY DELIVERY"}</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export default CustomerSearchForm
